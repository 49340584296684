<template>
  <div class="login-wrap">
    <div class="login-box">

      <template v-if="step === 1">
        <div class="title">{{ t('登录') }}</div>
        <div class="sub-title">{{ t('手机号/账号') }}</div>

        <van-field
            v-if="step === 1"
            v-model="loginParams.username"
            :formatter="formatter"
            autocomplete="off"
            center
            clearable
            :placeholder="t('请输入手机号或账号')"
        />

<!--        防止自动填充-->
        <input type="password" hidden autocomplete="new-password" />

        <div class="btn" @click="nextStep">{{ t('下一步') }}</div>
        <div class="create-account" @click="openRegister">{{ t('创建一个账户', [store.state.publicConfig.title]) }}</div>
      </template>

      <template v-if="step === 2">
        <van-icon name="arrow-left" class="last-step" @click="step -= 1" />
        <div class="title">{{ t('请输入您的密码') }}</div>
        <div class="sub-title">{{ t('密码') }}</div>

        <van-field
            v-model="loginParams.password"
            :formatter="formatter"
            :type="inputType"
            autocomplete="off"
            center
            clearable
            maxlength="16"
            :placeholder="t('请输入您的密码')"
        >
          <template #button>
            <div class="eye-icon" @click="changePassWordType">
              <van-image v-if="!showEye" :src="themeImgs.commonEyeOpened"/>
              <van-image v-else :src="themeImgs.commonEyeClosed"/>
            </div>
          </template>

        </van-field>

        <!--        防止自动填充-->
        <input type="password" hidden autocomplete="new-password" />

        <van-checkbox name='rememberMe' v-model="isRememberMe">
          {{ t('记住密码') }}
        </van-checkbox>

        <div class="btn" @click="openVerify">{{ t('登录') }}</div>

      </template>

    </div>

    <div class="service-forget" @click="setShowServicePopup(true)">
      <van-image :src="themeImgs.loginRegCsIcon" />{{ t('联系客服') }}
    </div>

    <!--    验证码弹框-->
    <van-dialog v-model:show="showVerifyCode"
                :show-confirm-button="false"
                :close-on-click-overlay="true"
                class="verify-code-dialog"
    >
      <van-image class="close-icon" :src="themeImgs.loginRegCloseIcon" @click="showVerifyCode = false" />

      <template v-if="codeType==1">
        <div class="label">{{ t('输入验证码') }}</div>
        <div class="wrapper">
          <van-field
              v-model="loginParams.code"
              :formatter="formatter"
              autocomplete="off"
              center
              maxlength="4"
          >
          </van-field>

          <van-image :src="graphicCodeImg" class="code-img" @click="handleGetGraphicCode"/>

        </div>

        <div class="btn" @click="handleLogin">{{ t('确定') }}</div>
      </template>

      <div v-show="codeType==3">
        <div class="label">{{ t('点击进行验证') }}</div>
        <Geet ref="geetRef" @handleGetGeetSession="handleGetGeetSession"
              @handleUpdateGeetStatus="handleUpdateGeetStatus"/>

        <div class="btn" @click="handleLogin">{{ t('确定') }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script setup>
import {computed, defineEmits, onBeforeMount, reactive, ref, watch, defineProps} from "vue";
import {useStore} from "vuex";
import {Toast} from "vant";
import {getCodeType, getGraphicCode, getScrap, login} from "@/api/api";
import wsConnection from "@/utils/websocket"
import Geet from "@/components/Geet"
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
// defineOptions({
//   name: 'Login'
// })

const props = defineProps({
  show: {
    type: Boolean
  }
})

const emit = defineEmits(['update:showRegister'])
const store = useStore();
const geetRef = ref()

// 默认行为验证码状态
const geetStatus = ref(1)

// 改变行为验证码状态
const handleUpdateGeetStatus = val => {
  geetStatus.value = val
}

// 存储验证码方式
const codeType = ref('')

// 获取验证码方式
const handleGetCodeType = () => {
  getCodeType().then(res => {
    if (res.result == 200000) {
      codeType.value = res.data.type
      if (res.data.type == 1) {
        handleGetGraphicCode()
      } else {
        geetRef.value.init(res.data)
        loginParams.session = res.data.session_id
      }
    }
  })
}

// 获取极验证session
const handleGetGeetSession = () => {
  handleGetCodeType()
}

// 存储图形验证码
const graphicCodeImg = ref('')

// 获取图形验证码
const handleGetGraphicCode = () => {
  getGraphicCode().then(res => {
    if (res.result == 200000) {
      graphicCodeImg.value = res.data.captcha
      loginParams.session = res.data.session
    }
  })
}

onBeforeMount(() => {
  // handleGetCodeType()
  handleGetRemember()
})

// 过滤输入的中文
const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
const inputType = ref("password");
const showEye = ref(true);
const loginParams = reactive({
  username: "", //用户名
  password: "", //密码
  code: "", // 验证码
  session: "",
  is_captcha: 1
});

// 显示密码
const changePassWordType = () => {
  showEye.value = !showEye.value;
  if (showEye.value) {
    inputType.value = "password";
  } else {
    inputType.value = "text";
  }
};

// 是否记住密码
const isRememberMe = ref(true)
const handleGetRemember = () => {
  if (isRememberMe.value) {
    loginParams.username = !!localStorage.getItem("username") ? localStorage.getItem("username") : ''
    loginParams.password = !!localStorage.getItem("password") ? localStorage.getItem("password") : ''
    loginParams.code = ''
    loginParams.session = ''
  } else {
    loginParams.username = ''
    loginParams.password = ''
    loginParams.code = ''
    loginParams.session = ''
  }

}
// 点击登录
const handleLogin = () => {
  if (!loginParams.username) {
    return Toast.fail(t('请输入手机号或账号'));
  } else if (!loginParams.password) {
    return Toast.fail(t('请输入密码'));
  } else if (codeType.value == 1 && !loginParams.code) {
    return Toast.fail(t('请输入验证码'));
  } else if (codeType.value == 3 && geetStatus.value != 4) {
    return Toast.fail(t('请点击进行验证'));
  } else {
    login(loginParams).then((res) => {
      if (res.result == 200000) {
        getScrap()
        Toast.success(t('登录成功'));
        store.dispatch("saveLoginInfosActions", res.data);
        store.commit('setLoginRegisterInfo', { flag: false }) // 关闭登录注册弹框
        wsConnection.initWebSocket()
        if (isRememberMe.value) {
          localStorage.setItem("rememberMe", isRememberMe.value);
          localStorage.setItem("username", loginParams.username);
          localStorage.setItem("password", loginParams.password);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }

      location.reload()
      } else {
        if (codeType.value == 3) {
          handleGetCodeType()
        } else {
          handleGetGraphicCode()
        }
        Toast.fail(res.msg)
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 是否显示用户名或手机号/邮箱登录结构
const showUsernameLogin = ref(true)
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const setShowServicePopup = (flag) => {
  store.commit('setShowServicePopup', flag)
}

const openRegister = () => {
  emit('update:showRegister', true)
}

const step = ref(1)

const showVerifyCode = ref(false)

const openVerify = () => {
  showVerifyCode.value = true
}

watch(() => showVerifyCode.value, val => {
  if (val) {
    handleGetCodeType()
  }
})

watch(() => props.show, val => {
  if (!val) {
    step.value = 1
  }
})

const nextStep = () => {
  if (!loginParams.username) {
    return Toast.fail(t('请输入手机号或账号'));
  }
  step.value += 1
}
</script>

<style lang="less" scoped>
.login-wrap {
  position: relative;
  height: 100%;
  padding: 0 16px;

  .login-box {
    .last-step {
      position: absolute;
      top: 7px;
      left: 5px;
      font-size: 23px;
      z-index: 100;
    }

    .title {
      padding-top: 42px;
      margin-bottom: 10px;
      font-family: PingFang SC;
      font-size: 28px;
      font-weight: 400;
      line-height: 39px;
      color: #333;
    }

    .sub-title {
      margin-bottom: 4px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0.5px;
      color: #7C8083;
    }

    .create-account {
      margin-top: 14px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.5px;
      color: #2EBD85;
    }

    :deep(.van-cell) {
      height: 40px;
      padding: 0 0 0 23px;
      background: #F5F5F5;
      border-radius: 8px;

      &::after {
        border: none;
      }

      input {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;

        &::placeholder {
           color: #A4A4A4;
          font-size: 12px;
         }
      }

      .eye-icon {
        display: flex;
        align-items: center;
        padding-right: 12px;

        .van-image {
          width: 32px;
          height: 32px;
        }
      }
    }

    ::v-deep .van-checkbox {
      height: 24px;
      margin-top: 14px;

      .van-checkbox__icon {
        width: 16px;
        height: 16px;

        .van-badge__wrapper {
          width: 100%;
          height: 100%;
          line-height: 16px;
        }
      }
    }
  }

  .service-forget {
    position: fixed;
    left: 50%;
    bottom: 18px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #2EBD85;
    white-space: nowrap;

    .van-image {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }

  .btn {
    width: 100%;
    height: 47px;
    margin-top: 30px;
    border-radius: 8px;
    background: #2EBD85;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
    color: #333;
  }
}
</style>

<style lang="less">
.verify-code-dialog {
  width: 308px;
  padding-bottom: 24px;

  .van-dialog__content {
    padding: 0 7px;
  }

  .close-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 20px;
    height: 20px;
  }

  .label {
    padding-top: 36px;
    margin-bottom: 8px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #666;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .van-cell {
      width: 200px;
      height: 40px;
      padding: 0 0 0 23px;
      background: #F5F5F5;
      border-radius: 8px;

      &::after {
        border: none;
      }

      input {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;

        &::placeholder {
          color: #A4A4A4;
        }
      }
    }

    .code-img {
      width: 90px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .btn {
    margin-top: 26px;
  }
}
</style>
