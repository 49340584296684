<template>
  <div id="captcha">
    <div id="text" v-if="chatStataus==1">
      {{ $t('行为验证™ 组件加载中') }}
    </div>
    <div id="wait" v-else-if="chatStataus==2">
      <div class="loading">
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
      </div>
    </div>
    <div
      class="geetest_radar_btn"
      v-else-if="chatStataus==3"
      @click="btnClick">
      <div class="geetest_radar">
        <div class="geetest_ring"></div>
        <div class="geetest_dot"></div>
      </div>
      <div class="geetest_radar_tip">
        <div class="geetest_radar_tip_content">
          {{ $t('点击进行验证') }}
        </div>
      </div>
    </div>
    <div
      class="geetest_success_btn"
      :class="{geetest_success_ani: chatStataus==4}"
      v-else>
      <div class="geetest_success_box">
        <div class="geetest_success_show">
          <div class="geetest_success_pie"></div>
          <div class="geetest_success_filter"></div>
          <div class="geetest_success_mask"></div>
        </div>
        <div class="geetest_success_correct">
          <div class="geetest_success_icon"></div>
        </div>
      </div>
      <div class="geetest_success_radar_tip">
        {{ $t('验证成功') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { doGeetValidate } from '@/api/api'
export default {
  data () {
    return {
      chatStataus: 1,
      isGisn: null,
      capValidate: null
    };
  },
  watch: {
    chatStataus (val) {
      this.$emit('handleUpdateGeetStatus', val);
    }
  },
  methods: {
    // init () {
    //   getGeetCode({
    //     t: new Date().getTime()
    //   }).then(({ result, data }) => {
    //     if (result === 200000) {
    //       this.chatStataus = 2;
    //       this.$emit('handleGetGeetSession', data.session_id);
    //       setTimeout(() => {
    //         this.chatStataus = 3;
    //         this.initGeet(data);
    //       }, 380);
    //     }
    //   });
    // },
    btnClick (val) {
      this.isGisn = val;
      if (this.isGisn) {
        this.capValidate.showCaptcha();
      }
    },
    init (item) {
      const _this = this;
      console.log('1,页面初始化，调用极验接口1，进行图形验证码的加载');
      // 极验第一次，请求回来的参数
      const handlerEmbed = capValidate => {
        console.log('初始化后')
        capValidate.onReady(() => {
          this.chatStataus = 2;
          setTimeout(() => {
            this.chatStataus = 3;
          }, 380);
        }).onSuccess(() => {
          const getVal = capValidate.getValidate();
          doGeetValidate({...getVal, session_id: item.session_id}).then(({ result, data, msg }) => {
            if (result === 200000 && data.is_verify == 'success') {
              // 极验校验的参数，将其传给服务端，进行校验。
              console.log('3，图形验证通过，将数据传递给父组件，进行服务端验证');
              _this.chatStataus = 4;
            } else {
              Toast.fail(msg)
              capValidate.reset();
              this.$emit('handleGetGeetSession');
            }
          });
        }).onError(() => {
          // 图形验证失败
        });
        // 极验图片触发之后，修改控制变量额布尔值，实现再次触发
        _this.capValidate = capValidate;
      };

      const botion = this.$store.state.publicConfig.botion

      const langObj = {
        vi: 'eng',
        zh: 'zho'
      }

      const configs = {
        product: 'bind',
        captchaId: item.id, // 替换为您的 Botion cap
        language: langObj[language]
      }
      //   初始化极验
      initBotion(configs,handlerEmbed);
      if (botion.apiServers && botion.apiServers.length && botion.staticServers && botion.staticServers.length) {
        configs.apiServers = botion.apiServers
        configs.staticServers = botion.staticServers
      }
      // //   初始化极验
      // initBotion(configs,handlerEmbed);
    }
  }
};
</script>

<style lang="scss" scoped>
#captcha {
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 36px;
  margin-bottom: 10px;
}
#text {
  text-align: center;
  border-radius: 2px;
  background-color: #F3F3F3;
  color: #ccc;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  border-radius: 14px;
}
#wait {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: center;
  border-radius: 14px;
  background-color: #F3F3F3;
}
.loading {
  margin: auto;
  width: 48px;
  height: 20px;
}
.loading-dot {
  float: left;
  width: 4px;
  height: 4px;
  margin: 10px 4px;
  background: #ccc;
  border-radius: 14px;
  opacity: 0;
  box-shadow: 0 0 2px black;
  animation: loadingFade 1s infinite;
}
.loading-dot:nth-child(1) {
  animation-delay: 0s;
}
.loading-dot:nth-child(2) {
  animation-delay: 0.1s;
}
.loading-dot:nth-child(3) {
  animation-delay: 0.2s;
}
.loading-dot:nth-child(4) {
  animation-delay: 0.3s;
}
@-webkit-keyframes loadingFade {
  0% { opacity: 0; }
  50% { opacity: 0.8; }
  100% { opacity: 0; }
}
@-moz-keyframes loadingFade {
  0% { opacity: 0; }
  50% { opacity: 0.8; }
  100% { opacity: 0; }
}
@keyframes loadingFade {
  0% { opacity: 0; }
  50% { opacity: 0.8; }
  100% { opacity: 0; }
}
.geetest_radar_btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding-left: 12px;
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #2EBD85;
  background-image: linear-gradient(180deg, #fff 0%,#f3f3f3 100%);
  &:hover {
    cursor: pointer;
    background-image: linear-gradient(0deg, #fff 0%,#f3f3f3 100%);
  }
  .geetest_radar {
    position: relative;
    width: 15px;
    height: 15px;
    transition: all .5s ease;
    .geetest_ring {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      transform: scale(0.4);
      transition: all .5s ease;
      box-shadow: inset 0 0 0 1px #2EBD8533;
      background: rgba(46, 189, 133, 0.2);
      transform: scale(1);
    }
    .geetest_dot {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      transform: scale(0.4);
      transition: all .5s ease;
      background: #2EBD85;
    }
  }
  .geetest_radar_tip {
    margin-left: 5px;
    letter-spacing: 1.3px;
    line-height: 1;
    box-sizing: border-box;
    color: #666;
    white-space: nowrap;
    .geetest_radar_tip_content {
      font-size: 12px;
    }
  }
}
.geetest_success_btn {
  position: absolute;
  top: 0px;
  border: 1px solid #ccc;
  border-radius: 14px;
  width: 100%;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-color: #26C267;
  right: 0;
  background: #EEFFF5;
  transition: width ease;
  .geetest_success_box {
    position: absolute;
    top: -1px;
    left: 4px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    transform: scale(0.7);
    .geetest_success_show {
      position: relative;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      background-color: #EEFFF5;
      .geetest_success_pie {
        animation: geetest_success_pie 0.7s ease both;
        position: absolute;
        left: 50%;
        top: 0;
        border: 2px solid #80D6AC;
        border-left: none;
        border-radius: 0 100% 100% 0 / 0 50% 50% 0;
        width: 50%;
        height: 100%;
        transform: rotate(25deg);
        transform-origin: 0 50%;
      }
      .geetest_success_filter {
        animation: geetest_success_filter 0.7s linear both;
        position: absolute;
        left: 0;
        top: 0;
        border: 2px solid #80D6AC;
        border-right: none;
        border-radius: 100% 0 0 100% / 50% 0 0 50%;
        width: 50%;
        height: 100%;transform: rotate(25deg);
        transform-origin: 100% 50%;
        opacity: 0;
      }
      .geetest_success_mask {
        animation: geetest_success_mask 0.7s linear both;
        border: none;
        border-radius: 0;
        background-color: #EEFFF5;
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        transform: rotate(25deg);
        transform-origin: 0 50%;
      }
    }
    .geetest_success_correct {
      position: absolute;
      right: -4px;
      top: -4px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      .geetest_success_icon {
        animation: geetest_success_correct 0.7s ease both;
        position: absolute;
        top: -18px;
        right: -18px;
        width: 18px;
        height: 18px;
        background-size: 322.22222%;
        background: url('~@/assets/captcha/sprite.1.5.8.png') no-repeat 0 70.65637%;
        transform: translate(-28px, 28px);
      }
    }
  }
  .geetest_success_radar_tip {
    position: absolute;
    top: 9px;
    left: 36px;
    box-sizing: border-box;
    font-size: 12px;
    color: #666;
    white-space: nowrap;
    text-align: left;
    transform: translate3d(0, 0, 0);
    color: #18A452;
    line-height: 1;
  }
}
.geetest_success_ani {
  width: 100%;
  animation: geetest_wait_success .5s ease;
}
@keyframes geetest_wait_success {
  0% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes geetest_wait_success {
  0% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
@keyframes geetest_wait_compute {
  60% {
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -webkit-transform: scale(0.75);
    transform: scale(0.75)
  }
}
@-webkit-keyframes geetest_wait_compute {
  60% {
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -webkit-transform: scale(0.75);
    transform: scale(0.75)
  }
}
@keyframes geetest_success_pie {
  25% {
    -moz-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg)
  }
  100% {
    -moz-transform: rotate(-275deg);
    -ms-transform: rotate(-275deg);
    -webkit-transform: rotate(-275deg);
    transform: rotate(-275deg)
  }
}
@-webkit-keyframes geetest_success_pie {
  25% {
    -moz-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg)
  }
  100% {
    -moz-transform: rotate(-275deg);
    -ms-transform: rotate(-275deg);
    -webkit-transform: rotate(-275deg);
    transform: rotate(-275deg)
  }
}
@keyframes geetest_success_filter {
  50.9% {
    opacity: 0
  }
  51% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}
@-webkit-keyframes geetest_success_filter {
  50.9% {
    opacity: 0
  }
  51% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}
@keyframes geetest_success_mask {
  50.9% {
    opacity: 1
  }
  51% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}
@-webkit-keyframes geetest_success_mask {
  50.9% {
    opacity: 1
  }
  51% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}
</style>
