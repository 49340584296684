export const uploadIcon = `<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="36" cy="36" r="36" fill="#CCCCCC"/>
<rect x="17" y="34" width="39" height="4" rx="2" fill="white"/>
<rect x="34.5" y="55.5" width="39" height="4" rx="2" transform="rotate(-90 34.5 55.5)" fill="white"/>
</svg>
`

export const warningIcon = `
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.3491 3.73872L37.2339 30.8657C37.9109 31.9533 37.1288 33.3613 35.8476 33.3613H2.07812C0.796996 33.3613 0.0149163 31.9533 0.691903 30.8657L17.5767 3.73872C18.2157 2.71209 19.7101 2.71209 20.3491 3.73872ZM20.1875 13.4263C20.1875 12.7745 19.6641 12.2461 19.0186 12.2461C18.373 12.2461 17.8496 12.7745 17.8496 13.4263V22.5327C17.8496 23.1844 18.373 23.7129 19.0186 23.7129C19.6641 23.7129 20.1875 23.1844 20.1875 22.5327V13.4263ZM19 24.9004C18.0572 24.9004 17.293 25.673 17.293 26.626C17.293 27.579 18.0572 28.3516 19 28.3516C19.9428 28.3516 20.707 27.579 20.707 26.626C20.707 25.673 19.9428 24.9004 19 24.9004Z" fill="#333333"/>
</svg>
`
