<template>
  <div class="app-wrapper">
    <router-view v-slot="{ Component }">
      <keep-alive >
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>

      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
    <!-- 全局消息提醒 -->
    <!-- <van-dialog
        v-model:show="isHaveNewOrder"
        title="消息提醒"
        @confirm="handleComfirm"
        class="global-dialog"
    >
        <p>你有新的订单状态，请立即处理</p>
    </van-dialog> -->
    <div class="order-status-box" v-show="isHaveNewOrder" @click="handleComfirm">
      <p>{{ t('你有新的订单状态') }}</p>
      <div v-if="locale !== 'vi'" class="immediate-box">
        <span>{{ t('请立即处理') }}</span>
        <van-icon :name="themeImgs.homeDoubleRightArrowIcon"/>
      </div>
    </div>
    <!-- 扫码支付密码弹框 -->
    <van-dialog v-model:show="showCodePayDialog"
                :title="t('付款')"
                show-cancel-button
                @close="handleCodePay('close')"
                @confirm="handleCodePay('pay')"
                class="pay-password-dialog"
    >
      <van-field v-model="codePayPassWordValue"
                 type="password"
                 :label="t('支付密码')"
                 :placeholder="t('请输入支付密码')"
                 autocomplete="off"
                 class="pay-password-dialog-cell"
      />
    </van-dialog>

    <audio ref="myAudio" :src="require('./assets/mp3/orderRemind.mp3')" muted/>

    <!-- 客服 -->
    <van-popup
        class="line-pop"
        :class="{ darkTheme:  ['black'].includes(globalSiteTheme) }"
        v-model:show="showServicePopup"
        round
        position="bottom">

      <div v-if="isVietnamView" class="service-box">
        <div class="service-close" @click="handleCloseService"></div>
        <iframe
            ref="iframeRef"
            :src="store.state.sysConfig.manage"
            width="100%"
            height="100%"
            frameborder="none"
            allowfullscreen
        />
      </div>
      <template v-else>
        <div class="top">
          <div @click="setShowServicePopup(false)">{{ t('取消') }}</div>
          <div>{{ t('选择客服') }}</div>
          <div @click="openLine">{{ t('完成') }}</div>
        </div>
        <div class="manage" @click="checkLine(1, store.state.sysConfig.manage)"
             v-if="store.state.sysConfig.manage !== ''">
          <div>
            {{ t('客服线路1') }}
            <img class="fill-img" :src="themeImgs.commonCsYesIcon" v-if="lineSelect === 1"/>
          </div>
        </div>
        <div class="manage" @click="checkLine(2, store.state.sysConfig.manage1)"
             v-if="store.state.sysConfig.manage1 !== ''">
          <div>
            {{ t('客服线路2') }}
            <img class="fill-img" :src="themeImgs.commonCsYesIcon" v-if="lineSelect === 2"/>
          </div>
        </div>
      </template>
    </van-popup>

    <!--    收付款-->
    <paymentView />

    <!--    关于我们-->
    <aboutUsView />

    <!--    登录注册-->
    <loginAndRegisterView />
  </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import wsConnection from "@/utils/websocket";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {codePay, getOrderRemind, getPublicConfig, getSysConfig, getMemberAmount} from '@/api/api'
import {Toast} from 'vant'
import ZhPayment from "@/views/Payment/Index";
import ViPayment from '@/viViews/Payment/Index'
import ZhAboutUs from "@/views/AboutUs";
import ViAboutUs from "@/viViews/AboutUs";
import ZhLoginAndRegister from '@/views/LoginAndRegister/Index'
import ViLoginAndRegister from '@/viViews/LoginAndRegister/Index'
import { useI18n } from 'vue-i18n'
import { Locale } from 'vant';
import zhCN from 'vant/es/locale/lang/zh-CN';
import viVn from 'vant/es/locale/lang/vi-VN';

const { t, locale } = useI18n()
const $route = useRoute();
const $router = useRouter();
const store = useStore();
const myAudio = ref()

// 引入语言包
const langObj = {
  zh: () => {
    Locale.use('zh-CN', zhCN);
  },
  vi: () => {
    Locale.use('vi-VN', viVn);
  }
}

langObj[locale.value]()

const siteTitleObj = {
  "agpay": "AGPAY",
  "qtpay": 'QTPAY',
  "88pay": '88PAY',
  "aipay": "AIPAY",
  "rmbpay": 'RMBPAY',
}
onMounted(() => {
  document.getElementById("siteTitle").innerText = siteTitleObj[siteChannel];
  document.getElementById("favicon").href = require('@siteResources/logo/favicon.png');

  // 在safari中必须先触发一次play之后才能在代码中被动播放

  const callBack = () => {
    playAudio()

    let timer = setTimeout(() => {
      myAudio.value.pause()
      myAudio.value.muted = false
      myAudio.value.currentTime = 0
      clearTimeout(timer)
      document.removeEventListener('click', callBack)
    }, 1)
  }
  document.addEventListener('click', callBack)
})

onBeforeMount(() => {
  handleGetSysConfig()
  ifLogin()
  if (store.state.loginInfos.token) {
    wsConnection.initWebSocket()
    handleGetOrderRemind()
    handleGetMemberAmount()
  }
  setTheme()
  handleGetPublicConfig()
})

//  收付款
const paymentView = computed(() => {
  if (siteView === 'viViews') {
    return ViPayment
  }
  return ZhPayment
})

//  关于我们
const aboutUsView = computed(() => {
  if (siteView === 'viViews') {
    return ViAboutUs
  }
  return ZhAboutUs
})

//  登录注册
const loginAndRegisterView = computed(() => {
  if (siteView === 'viViews') {
    return ViLoginAndRegister
  }
  return ZhLoginAndRegister
})

const playAudio = () => {
  myAudio.value && myAudio.value.play()
}

const globalSiteTheme = computed(() => store.state.globalSiteTheme)

watch(() => $route.query.agTheme, val => {
  store.commit('setGlobalSiteTheme', val || siteTheme)
}, {
  immediate: true
})

// watch(() => $route.name, val => {
//   if (val === 'TradingHall') {
//     document.body.style.overflow = 'hidden'
//   } else {
//     document.body.style.overflow = 'auto'
//   }
// }, {
//   immediate: true
// })

// 获取订单状态提醒
const handleGetOrderRemind = () => {
  //    name === bitcoin,比特币消息，不需要调用接口
  const fullPath = window.location.href
  if (orderRemindObj.value.name !== 'bitcoin' && !fullPath.includes('checkoutCounter')) {
    getOrderRemind().then(res => {
      if (res.result === 200000) {
        if (res.data.count > 0) {
          store.commit('setUntreatedNum', res.data.count)
        } else {
          store.commit('setUntreatedNum', 0)
        }
      }
    })
  }
}

const ifLogin = () => {
  // 监听从综合iframe那过来的参数
  // 解密
  if (window.parent.frames && window.parent.frames['agIframe'] && window.location.href.indexOf('token') == -1) {
    if (getUrlParams(location.href)['query']) {
      let data = JSON.parse(decodeURIComponent(getUrlParams(location.href)['agInfos']))
      let actualName = decodeURIComponent(getUrlParams(location.href)['actualName'])
      store.dispatch("saveLoginInfosActions", data)
      store.dispatch('saveActualNameActions', actualName)
      let order = JSON.parse(decodeURIComponent(getUrlParams(location.href)['query']))
      if (order.type == 1) {
        $router.push({
          path: 'buyerOrderDetails',
          query: {
            order_sn: order.order_sn
          }
        })
      } else {
        $router.push({
          path: 'sellerOrderDetails',
          query: {
            order_sn: order.order_sn
          }
        })
      }
    } else if (getUrlParams(location.href)['agInfos']) {
      let data = JSON.parse(decodeURIComponent(getUrlParams(location.href)['agInfos']))
      let actualName = decodeURIComponent(getUrlParams(location.href)['actualName'])
      store.dispatch("saveLoginInfosActions", data);
      store.dispatch('saveActualNameActions', actualName)
    }
  } else if (window.location.href.indexOf('token') != -1 && window.location.href.indexOf('three_username') == -1) {
    // （iframe嵌套）从其他第三方平台过来的，通过https://antpay-h5test.mayibet.site/#/home?token=123&terminal=1 这样的方式能够进行免密登录
    let data = getUrlParams(location.href)['token']
    store.dispatch("saveLoginInfosActions", {token: data});
    $router.push('/home')
    localStorage.setItem('isFromThird', true)
  }
  // 从其他第三方平台过来的，通过https://antpay-h5test.mayibet.site/#/home?token=123&terminal=1 这样的方式能够进行免密登录
  // if (!window.parent.frames['agIframe'] && window.location.href.indexOf('token') != -1 && window.location.href.indexOf('three_username') == -1 ) {
  //     let data = getUrlParams(location.href)['token']
  //     store.dispatch("saveLoginInfosActions", {token:data});
  //     $router.push('/home')
  //     localStorage.setItem('isFromThird', true)
  // }
}

// 获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}

// 换肤
const setTheme = () => {
  // let url = ''
  // let theme = 'black'
  // if(window.parent.frames['agIframe']) {
  //     url = window.parent.frames['agIframe'].location.href
  //     let passTheme = getUrlParams(url)['agTheme']
  //     if (['black','blue','golden','red','green'].includes(passTheme)) {
  //         theme = passTheme
  //         localStorage.setItem('agTheme', passTheme)
  //     } else {
  //         theme = 'black'
  //         localStorage.setItem('agTheme', 'black')
  //     }
  // } else {
  //     theme = localStorage.getItem('agTheme') && localStorage.getItem('agTheme') !='undefined' ? localStorage.getItem('agTheme') : 'black'
  // }
  //
  // if(theme == 'black_gold') {
  //     theme = 'black'
  // }

  let theme = 'green'
  // siteTheme 主题颜色，是打包时指定的全局变量
  const paramsTheme = getUrlParams(location.href)['agTheme'] || siteTheme
  if (['green'].includes(paramsTheme)) {
    theme = paramsTheme
  }

  import(`@/assets/theme/${theme}/index.js`).then((res) => {
    store.dispatch('saveThemeImgsActions', res.default)
    const $body = document.querySelector('body');
    $body.className = `${theme}`;
    if (theme == 'black') {
      $body.style.color = '#fff';
      $body.style.background = '#242424';
    }
  })
}

// 获取系统配置
const handleGetSysConfig = () => {
  getSysConfig().then(res => {
    if (res.result == 200000) {
      store.dispatch('saveSysConfigActions', res.data.data)
    }

    if (res.result == 30000) {
      const data = {
        manage: res.data.info.kefu_url,
        manage1: res.data.info.kefu_url1,
      }
      store.dispatch('saveSysConfigActions', data)
    }
  })
}

// 获取公共配置
const handleGetPublicConfig = () => {
  getPublicConfig().then(res => {
    if (res.result == 200000) {
      store.dispatch('savePuclicConfigActions', res.data.list)
    }
  })
}

// 是否显示有无订单状态
const isHaveNewOrder = ref(false);
// 存储订单状态提醒数据
const orderRemind = ref([]);
// 计算属性实现视图更新
const orderRemindObj = computed(() => store.state.orderRemind);
watch(() => [orderRemindObj.value, store.state.quickBuyNotify], (newValue) => {
      if (store.state.loginInfos.token) {
        handleGetOrderRemind()
        handleGetMemberAmount()
      }

      // 收银台页面不展示订单通知
      if (!$route.fullPath.includes('checkoutCounter')) {
        // 消息语音提示
        playAudio()
        // 计算属性实现视图更新--新订单提醒
        orderRemind.value = newValue[0];
        isHaveNewOrder.value = true
      }
    },
    {
      deep: true, // name是一个对象，需要进行深度监听
    }
);

const themeChange = computed(() => store.state.theme);
watch(
    themeChange,
    (newValue) => {
      handleSetThemeVars()
    },
    {
      deep: true, // name是一个对象，需要进行深度监听
    }
);

const handleComfirm = () => {
  let id = 0;
  console.log('orderRemind', orderRemind.value)
  //  跳转快捷买币详情  store.state.quickBuyNotify
  if (orderRemind.value.name === 'vn_recharge_order') {
    if ($route.query.order_sn == orderRemind.value.order_sn) {
      $router.push({
        path: "quickOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
          id: id++,
        },
      });
    } else {
      $router.push({
        path: "quickOrderDetails",
        query: {
          order_sn:orderRemind.value.order_sn,
        },
      });
    }
  } else if (orderRemind.value.name === 'bitcoin') {
    $router.push({
      path: '/order',
      query: {
        bitType: orderRemind.value.type
      }
    })
  } else if (orderRemind.value.type == 1) {
    if ($route.query.order_sn == orderRemind.value.order_sn) {
      $router.push({
        path: "buyerOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
          id: id++,
        },
      });
    } else {
      $router.push({
        path: "buyerOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
        },
      });
    }
  } else if (orderRemind.value.type == 2) {
    if ($route.query.order_sn == orderRemind.value.order_sn) {
      $router.push({
        path: "sellerOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
          id: id++,
        },
      });
    } else {
      $router.push({
        path: "sellerOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
        },
      });
    }
  } else if (orderRemind.value.type == 3) {
    localStorage.setItem('firstTabIndex', '2')
    $router.push({
      path: "order",
    });
  } else if (orderRemind.value.type == 4) {
    if ($route.query.order_sn == orderRemind.value.order_sn) {
      $router.push({
        path: "quickSellOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
          id: id++,
        },
      });
    } else {
      $router.push({
        path: "quickSellOrderDetails",
        query: {
          order_sn: orderRemind.value.order_sn,
        },
      });
    }
  }

  orderRemind.value = []
  isHaveNewOrder.value = false
};

// 是否显示支付密码框
const showCodePayDialog = ref(false)

// 暴露传递给app的函数
window["handleOpenCodePayDialog"] = (order) => {
  if (order) {
    if (typeof order === 'string') {
      handleOpenCodePayDialog(JSON.parse(order))
      return
    }
    handleOpenCodePayDialog(order)
  }
}

//  获取到二维码的信息，打开支付窗口
watch([() => store.state.isScanSuccess, () => $route.query.data], ([val, val1]) => {
  if (val && val1) {
    console.log('获取的扫码内容', JSON.parse(JSON.parse($route.query.data)))
    handleOpenCodePayDialog(JSON.parse(JSON.parse($route.query.data)))
  }
})

// 存储app传递来的数据
const ordersn = ref('')
// 打开支付密码弹框
const handleOpenCodePayDialog = (order) => {
  codePayPassWordValue.value = ''
  ordersn.value = order.order
  showCodePayDialog.value = true
}

// 存储支付密码
const codePayPassWordValue = ref('')

// 确认支付
const handleCodePay = (type) => {
  if (type === 'close') {
    showCodePayDialog.value = false
    ordersn.value = ''
    store.commit('setIsScanSuccess', false)
  }
  if (type === 'pay') {
    codePay({order_sn: ordersn.value, pay_password: codePayPassWordValue.value}).then(res => {
      if (res.result == 200000) {
        handleGetMemberAmount()
        Toast.success(t('支付成功'))
        showCodePayDialog.value = false
        ordersn.value = ''
        store.commit('setIsScanSuccess', false)
      } else {
        Toast.fail(res.msg)
      }
    })
  }
}

const showServicePopup = computed(() => store.state.showServicePopup)

const setShowServicePopup = (flag) => {
  store.commit('setShowServicePopup', flag)
}

// 客服弹窗
const lineUrl = ref(store.state.sysConfig.manage)
const lineSelect = ref(1)

const checkLine = (num, url) => {
  lineSelect.value = num
  lineUrl.value = url
}

const openLine = () => {
  setShowServicePopup(false)
  window.open(lineUrl.value)
}

const themeImgs = computed(() => store.state.themeImgs)

// 获取会员交易信息
const handleGetMemberAmount = () => {
  const fullPath = window.location.href
  console.log('路由', fullPath)
  if (!fullPath.includes('checkoutCounter')) {
    getMemberAmount().then(res => {
      if (res.result == 200000) {
        // memberDealInfo.value = res.data.info
        store.dispatch('saveMemberAmountActions', res.data.info)
      }
    })
  }
}

// 计算属性实现视图更新--金额数量变化
const moneyChangeObj = computed(() => store.state.moneyChangeInfos)

watch(moneyChangeObj, () => {
  if (store.state.loginInfos.token) {
    handleGetMemberAmount()
  }
}, {
  deep: true // name是一个对象，需要进行深度监听
})

//  是否是越南站
const isVietnamView = computed(() => {
  return siteView === 'viViews'
})

//  客服关闭
const handleCloseService = () => {
  store.commit('setShowServicePopup', false)
}
</script>

<style lang="less">
#app, .app-wrapper {
  height: 100%;
}
</style>

<style lang="less" scoped>
.service-box {
  width: 100%;
  height: 80vh;
  position: relative;
  .service-close {
    width: 40px;
    height: 38px;
    position: absolute;
    //background: red;
    right: 0;
    top: 0;
  }
}
:deep(.global-dialog) {
  .van-dialog__header {
    color: #fff;
  }

  p {
    padding: 20px 0;
    text-align: center;
    color: #fff;
  }
}

:deep(.line-pop) {
  background: #ffffff;

  &.darkTheme {
    color: #000
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    padding: 0 14px;
    border-bottom: 8px solid #F8F9FA;
  }

  .manage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #F8F9FA;

    > div {
      position: relative;

      .fill-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -25px;
        width: 20px;
      }
    }
  }
}

.order-status-box {
  position: fixed;
  right: 0;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  height: 60px;
  padding-left: 10px;
  //background: var(--btnBgColor);
  background: #2EBD85;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #fff;
  z-index: 1;

  p {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
  }

  .immediate-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    span {
      font-size: 10px;
      letter-spacing: 0.04em;
    }

    :deep(.van-icon) {
      .van-icon__image {
        width: 8px;
        height: 8px;
      }
    }
  }
}

:deep(.pay-password-dialog) {

  .van-dialog__header {
    padding: 16px 0;
    font-weight: 500;
    font-size: 18px;
    //color: var(--orderDialogTextColor);
    color: #535C66;
  }

  .van-button__text {
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 400;
    color: #535C66;
  }

  .pay-password-dialog-cell {
    flex-direction: column;
    background: none;
    background: transparent;

    input::placeholder {
      color: rgba(83, 92, 102, 0.6);
    }

    textarea::placeholder {
      color: rgba(83, 92, 102, 0.6);
    }

    .van-field__label {
      margin-bottom: 7px;

      label {
        color: #535C66;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .van-field__control {
      color: #535C66;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
