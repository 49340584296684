import ViRouter from './viRouter';
import DefaultRouter from './defaultRouter';

let router;
if (siteView === 'viViews') {
  router = ViRouter;
} else {
  router = DefaultRouter;
}

export default router;
