// 越南文
export default {
    // 公共
    "请求超时": "Yêu cầu hết thời gian",
    "请求超时服务器链接失败": "Liên kết máy chủ thời gian chờ yêu cầu không thành công",

    // components Geet
    "行为验证™ 组件加载中": "Xác minh hành vi ™ Đang tải thành phần",
    "点击进行验证": "Bấm để xác minh",
    "验证成功": "Xác minh thành công",

    // sideBar
    "您还未登录": "Chưa đăng nhập",
    "登录": "Đăng nhập",
    "注册": "đăng ký",
    "后查看": "Nhìn vào",
    "钱包地址": "Địa chỉ ví",
    "未实名": "Chưa xác minh",
    "已实名": "Tên thật",
    "审核中": "đang được xem xét",
    "复制成功": "Coppy thành công",
    "实名认证": "Xác minh tài khoản",
    "扫一扫": "Quét mã QR",
    "交易订单": "Hồ sơ đặt hàng",
    "收款信息": "Phương thức thanh toán",
    "资金明细": "Lịch sử giao dịch",
    "个人报表": "Biến động cá nhân",
    "代理佣金": "Ủy quyền đại diện",
    "关于我们": "Giới thiệu ứng dụng",
    "帮助中心": "Trung tâm trợ giúp",
    "在线客服": "Chăm sóc trực tuyến",
    "修改登录密码": "Thay đổi mật khẩu đăng nhập",
    "修改支付密码": "Thay đổi mật khẩu thanh toán",
    "退出登录": "Đăng xuất",
    "退出成功": "Thoát thành công",
    "RMB": "Việt Nam Đồng",

    // sellCoin
    "输入出售数量": "Nhập số tiền",
    "可售余额": "Số dư ví",
    "单次出售最小数量": "Số tiền bán tối thiểu",
    "收款方式": "Thanh toán",
    "出售方式": "Cách bán hàng",
    "不拆分": "Không chia tách",
    "拆分": "Tách ra",
    "出售": "Bán",
    "请输入支付密码": "Nhập mật khẩu thanh toán",
    "支付密码": "mật khẩu",
    "提示": "gợi ý",
    "请先添加收付款方式": "Vui lòng thêm phương thức thanh toán trước",
    "请输入出售数量": "Vui lòng nhập số lượng bán",
    "出售数量不能小于单次最小数量": "Số lượng bán không thể nhỏ hơn số lượng tối thiểu",
    "请选择收款方式": "Vui lòng chọn phương thức thanh toán",
    "上架成功": "Đặt thành công trên kệ",
    "操作失败，请检查网络重试或者联系客服": "Nếu hoạt động không thành công, vui lòng kiểm tra Internet để thử lại hoặc liên hệ với dịch vụ khách hàng",

    // Usdt
    "当前余额": "Số dư ví hiện tại",
    "币": "tiền tệ",
    "类型": "Đơn vị",
    "请选择USDT类型": "Vui lòng chọn loại USDT",
    "链路": "liên kết",
    "请选择USDT链路": "Vui lòng chọn liên kết USDT",
    "数量": "Số lượng",
    "请输入要充值的USDT数量": "Nhập số tiền USDT",
    "请输入要提现的USDT数量": "Nhập số tiền USDT",
    "地址": "Địa chỉ",
    "请选择USDT地址": "Vui lòng chọn địa chỉ USDT",
    "请输入提现的USDT-TRC20地址": "Nhập địa chỉ rút tiền",
    "密码": "mật khẩu",
    "注意": "Để ý",
    "转账时请核对收款地址是否一致，避免造成无法追回损失!": "Lưu ý: Vui lòng kiểm tra lại thông tin trước khi hoàn tất giao dịch để tránh xảy ra sự cố thất thoát tiền của quý khách !",
    "填写地址时请再次核实是否正确，避免造成无法追回损失!": "Chú ý: Nếu xác minh sai địa chỉ, chúng tôi sẽ không chịu trách nhiệm khoản thất thoát này!",
    "支付凭证": "Chứng từ thanh toán",
    "上传支付凭证": "Tải lên biên nhận thanh toán",
    "提交充值": "Gửi một bản nạp tiền",
    "提交提现": "Gửi rút tiền",
    "暂无数据": "Không có dữ liệu",
    "完成": "Liên hệ",
    "上传中": "tải lên",
    "USDT地址已复制": "Địa chỉ USDT đã được sao chép",
    "操作失败": "lỗi hệ thống",
    "当前账户余额不足": "Tài khoản hiện tại không đủ số dư",
    "请输入USDT数量": "Vui lòng nhập số USDT",
    "充值的USDT数量小于最低配置限制": "Số lượng USDT để sạc lại nhỏ hơn giới hạn cấu hình tối thiểu",
    "提现的USDT数量小于最低配置限度": "Số lượng USDT của việc rút thấp hơn giới hạn cấu hình tối thiểu",
    "USDT地址只能包含英文和数字": "Địa chỉ USDT chỉ có thể chứa tiếng Anh và số",
    "支付密码不能为空": "Mật khẩu thanh toán không thể trống",
    "报价": "Tỷ giá",

    // Deposit
    "充值": "Nạp tiền",

    // login
    "手机号/账号": "Số điện thoại di động/số tài khoản",
    "请输入手机号或账号": "Nhập số điện thoại di động hoặc số tài khoản",
    "下一步": "Kế tiếp",
    "创建一个账户": "Đăng ký tài khoản {0}",
    "请输入您的密码": "Vui lòng nhập mật khẩu của bạn",
    "记住密码": "Nhớ mật khẩu",
    "联系客服": "Liên hệ chăm sóc khách hàng chúng tôi",
    "输入验证码": "Nhập mã xác nhận",
    "确定": "Xác nhận",
    "请输入密码": "Xin vui lòng nhập mật khẩu",
    "请输入验证码": "vui lòng nhập mã xác nhận",
    "请点击进行验证": "Vui lòng bấm để xác minh",
    "登录成功": "đăng nhập thành công",
    "账户": "Tài khoản",

    // Register
    "创建账户": "tạo tài khoản",
    "账号": "tài khoản",
    "手机号": "Số điện thoại",
    "已有账号，去登录": "Đã có tài khoản, đăng nhập",
    "设置登录与支付密码": "Đặt mật khẩu đăng nhập và thanh toán",
    "验证码已发送": "Mã xác minh đã được gửi",
    "请输入短信验证码": "Vui lòng nhập mã xác minh SMS",
    "重新获取": "Recquire",
    "请输入登录密码": "Vui lòng nhập mật khẩu của bạn",
    "请输入数字与英文组合至少6位": "Ít nhất 6 chữ số, số + tiếng Anh",
    "请确认登录密码": "Xác nhận lại mật khẩu đăng nhập",
    "请保持登录密码一致": "Vui lòng điền trùng khớp với mật khẩu đăng nhập",
    "请输入手机号码": "Vui lòng nhập số điện thoại",
    "发送中": "gửi",
    "发送成功": "Gửi thành công",
    "请输入用户名": "Vui lòng nhập tên người dùng",
    "请再次确认密码": "Vui lòng xác nhận lại mật khẩu",
    "两次密码不一致": "Hai mật khẩu không nhất quán",
    "请设置支付密码": "Vui lòng đặt mật khẩu thanh toán",
    "注册成功": "Đăng ký thành công",
    "请输入账号": "Điền tên tài khoản",
    "请设置6位纯数字支付密码": "mật khẩu 6 chữ số",

    //AgencyCommission
    "全部": "Tất cả",
    "买单佣金": "Trả tiền cho một khoản hoa hồng",
    "卖单佣金": "Doanh thu",
    "至": "ĐẾN",
    "请输入搜索的订单编号": "Vui lòng nhập mã đơn tìm kiếm",
    "搜索": "Tìm kiếm",
    "已派发佣金": "Nhiệm vụ",
    "订单号": "Số đặt hàng",
    "返佣账号": "Tài khoản giảm giá",
    "交易金额": "Số lượng giao dịch",
    "买卖类型": "Mua và bán",
    "返佣比例": "Tỷ lệ hoa hồng",
    "返佣金额": "Hoa hồng",
    "返佣方式": "Cách trở lại",
    "返佣时间": "Thời gian giảm giá",
    "返佣派发": "Hạ giá",
    "没有更多了": "Không còn nữa",
    "买单": "Đơn mua",
    "卖单": "Đơn bán",
    "日结": "Ngày nọ",
    "周结": "Hàng tuần",
    "月结": "Hàng tháng",
    "已返佣": "Hạ giá",
    "未返佣": "Chú",
    "进行中": "Đang giao dịch",
    "已完成": "Đã bán",
    "已取消": "Đã hủy",
    "人工退回": "Bằng tay",
    "人工打币": "Tiền tệ nhân tạo",
    "出售中": "Đang treo",
    "已冻结": "Đông cứng",
    "交易中": "Đang giao dịch",
    "待支付": "Được trả tiền",
    "成功": "thành công",
    "失败": "thất bại",
    "取消": "Hủy bỏ",

    // FundRecord
    "人工加款": "Cộng tiền",
    "人工扣款": "Khấu trừ",
    "买币-成功": "Giao dịch mua thành công",
    "上架货币": "Đơn đã treo",
    "上架货币强制退回": "Đơn trả về",
    "卖币成功": "Giao dịch bán thành công",
    "api充值": "Nạp tiền API",
    "api提现": "Rút tiền API",
    "佣金": "Tiền hoa hồng",
    "用户转账": "Người dùng chuyển tiền",
    "用户收款": "Người dùng nhận tiền",
    "代理转账": "Đại lý chuyển tiền",
    "代理收款": "Đại lý nhận tiền",
    "USDT充值": "Nạp tiền USDT",
    "USDT提现": "Rút tiền USDT",
    "USDT提现退回": "Hoàn trả rút tiền USDT",

    // HelpCenter
    "买币": "Mua",
    "卖币": "Bán",
    "提现": "Rút",
    "快捷买币": "Mua nhanh",

    // ModifyLoginPassword
    "旧密码": "Mật khẩu cũ",
    "请输入旧密码": "Vui lòng nhập mật khẩu cũ",
    "新密码": "mật khẩu mới",
    "请输入新密码": "Vui lòng nhập mật khẩu mới",
    "确认密码": "Xác nhận mật khẩu",
    "确认": "xác nhận",
    "请再次输入新密码": "Vui lòng nhập lại mật khẩu mới",
    "两次新密码不一致": "Xác nhận lại mật khẩu không trùng khớp",
    "修改成功": "Sửa đổi thành công",

    // ModifyPaymentPassword
    "旧支付密码": "Mật khẩu cũ",
    "新支付密码": "Mật khẩu mới",
    "确认支付密码": "Xác nhận mật khẩu",

    // PersonalData
    "个人资料": "thông tin cá nhân",
    "设置头像": "Đặt Avatar",
    "修改昵称": "Thay đổi tên người dùng",
    "请输入新的昵称": "Vui lòng nhập biệt danh mới",
    "买单总额": "Tổng số xu đã mua",

    // PersonalReport
    "卖单总额": "Tổng số xu đã bán",
    "API充值总额": "Tổng số xu đơn api đã nạp",
    "API充值返点": "Tổng số xu đơn api đã nhận khuyến mãi",
    "API提现总额": "Tổng số xu đơn api đã rút",
    "交易纠纷订单总额": "Tổng số xu hỗ trợ lên điểm",
    "转账总额": "Tổng chuyển nhượng",
    "收款总额": "Tổng số biên nhận",

    // BuyerOrderDetails
    "订单详情": "Chi tiết đặt hàng",
    "发起订单": "Tạo đơn hàng",
    "卖家确认": "Xác nhận",
    "凭证已上传": "Đã tải lên",
    "卖家已打币": "Đã thanh toán",
    "卖家已暂停": "Đã tạm dừng",
    "订单编号": "Mã đơn hàng",
    "买家名字": "Tên người mua",
    "买家昵称": "Biệt danh",
    "金额": "Số lượng",
    "订单时间": "Thời gian tạo đơn",
    "付款方式": "Thanh toán",
    "支付宝": "ZALO",
    "微信": "MOMO",
    "银行卡": "BANK",
    "开户行": "Mở tài khoản",
    "银行卡号": "Số thẻ",
    "支付剩余时间": "Thời gian trả",
    "卖家名字": "Tên người bán",
    "卖家昵称": "Biệt danh bán",
    "取消订单": "hủy đơn hàng",
    "上传凭证": "Tải lên biên nhận",
    "买家上传支付凭证": "Người mua tải chứng từ",
    "已转账，等待卖方确认": "Đã chuyển khoản, chờ xác nhận",
    "查看凭证": "Kiểm tra biên nhận",
    "创建时间": "Thời gian tạo",
    "取消时间": "Hủy thời gian",
    "订单已取消": "Đơn đặt hàng đã bị hủy",
    "撤单备注": "Nhận xét",
    "冻结时间": "Thời gian băng",
    "订单已冻结": "Đã đóng băng đơn",
    "卖家暂停打币": "Tạm dừng thanh toán",
    "卖家收款二维码": "Mã QR người bán",
    "温馨提示": "Lời khuyên tử tế",
    "买家付款凭证": "chứng từ thanh toán",
    "是否撤单": "Hủy đơn",
    "撤单原因": "Lý do hủy",
    "撤单原因可为空": "Lý do hủy trống",
    "撤单成功": "Rút tiền thành công",
    "点击扫码付款": "Quét mã để trả",

    // Order
    "订单": "Đặt hàng",
    "卖币订单":"Đơn bán ",
    "挂单": "Đơn hàng chờ",
    "API订单": "Đơn nạp API",
    "加款": "Cộng tiền",
    "扣款": "Khấu trừ",
    "挂单编号": "Số đơn hàng treo",
    "下架": "Ra khỏi kệ",
    "总出售": "Tổng doanh thu",
    "失败原因": "Gây ra",
    "系统冻结": "Hệ thống đóng băng",
    "处理中": "Xử lý",
    "拒绝": "từ chối",
    "确认将该笔订单进行下架吗": "Bạn có xác nhận rằng đơn hàng đã bị xóa?",
    "下架成功": "Đã loại bỏ thành công",

    // SellerOrderDetails
    "卖单详情": "Chi tiết đơn bán",
    "购买数量": "Số lượng mua",
    "撤销": "Thu hồi",
    "等待买家付款": "Đang chờ người mua thanh toán",
    "暂停打币": "Tạm ngừng",
    "确认打币": "Xác nhận tiền xu",
    "买家已付款": "Người mua đã trả tiền",
    "请核实资金到账后再确认打币，切勿未收到转账直接打币造成损失。": "Hãy xác nhận rằng đã nhận được khoản thanh toán , nếu không nhận được khoản thanh toán mà xác nhận chuyển xu , 88pay sẽ không chịu trách nhiệm, cảm ơn !",
    "暂停打币成功": "Tiền kim loại thành công",
    "打币成功": "Đồng xu thành công",
    "我已转账": "Tôi đã thanh toán",
    "上传成功": "Tải lên thành công",

    // AddPayment
    "添加收付款方式": "Thêm phương thức thanh toán",
    "姓名": "Tên",
    "请输入真实姓名": "Vui lòng nhập tên thật",
    "支付宝账号": "Tài khoản zalo",
    "请输入支付宝账号": "Nhập tài khoản ZALO",
    "我的收款码": "Mã biên lai của tôi",
    "支付宝二维码": "tải lên",
    "上传二维码": "tải lên",
    "上传收款码": "tải lên",
    "上传我的收款码": "tải lên",
    "请输入银行卡卡号": "Nhập số thẻ ngân hàng",
    "选择银行": "chọn",
    "请选择银行": "Vui lòng chọn một ngân hàng",
    "添加": "Thêm vào",
    "上传失败": "Tải lên thất bại",
    "添加成功": "Thêm thành công",

    // BankList
    "请输入搜索的银行": "Vui lòng nhập ngân hàng tìm kiếm",

    // Payment
    "加载中": "đang tải",
    "添加收款信息": "Thêm thông tin biên lai",
    "支付宝名": "Tên ZALO",
    "查看二维码": "Mã QR",
    "禁用": "Vô hiệu hóa",
    "系统禁用": "Hệ thống vô hiệu hóa",
    "微信名": "Tên MOMO",
    "银行名": "Tên ngân hàng",
    "二维码": "Mã QR",

    // Announcement
    "站内信息": "Thông tin trên trang web",
    "公告内容": "Nội dung thông báo",

    // BannerDetail
    "Banner详情": "Chi tiết biểu ngữ",

    // BuyCoin
    "购买": "Mua",
    "输入购买数量": "Nhập số lượng mua hàng",
    "可购总数": "Tổng số mua hàng",
    "最小购买数量": "Số lượng mua tối thiểu",
    "卖家": "Người bán",
    "出售数量": "Số lượng",
    "可拆售": "Có thể tháo dỡ",
    "不可拆售": "Bán hàng giải nén",
    "支付": "Pay",
    "请输入购买数量": "Vui lòng nhập số lượng mua hàng",
    "购买数量不能小于最小数量": "Số lượng mua không thể nhỏ hơn số lượng tối thiểu",
    "请按照订单金额支付": "Thanh toán tiền theo đơn hàng",

    // fromThirdPay
    "充值金额": "Số tiền nạp",
    "订单时效": "Thời gian hiệu lực của đơn hàng",
    "pay余额": "Số dư khả dụng",
    "确认支付": "Xác nhận thanh toán",
    "获得更多": "Nhận thêm",
    "去充值": "Nạp xu",
    "您的余额不足，请充值": "Số dư của bạn không đủ, Vui lòng nạp thêm xu",
    "支付成功": "Thanh toán thành công",

    // fromThirdReg
    "初次使用设置": "Cài đặt cho mục đích sử dụng ban đầu",
    "设置账号": "Đặt số tài khoản",
    "首字母开头-6-20位": "Bắt đầu khi bắt đầu chữ 6-20 chữ số",
    "真实姓名": "Tên thực tế",
    "输入真实姓名": "Nhập tên thật",
    "设置登录密码": "Đặt mật khẩu đăng nhập",
    "设置支付密码": "Thiết lập mật khẩu thanh toán",
    "6位数字支付密码": "6 -Th số mật khẩu thanh toán",
    "请设置登录密码": "Vui lòng đặt mật khẩu đăng nhập",
    "设置成功": "Đặt thành công",

    // Home
    "暂无公告": "Không có thông báo",
    "回到充值页": "Quay lại trang sạc lại",
    "账号被冻结": "Tài khoản bị đóng băng",
    "请先进行实名认证": "Trước tiên xin vui lòng tiến hành xác minh tên thật",
    "实名审核中": "Tên thật đang chờ phê duyệt",
    "该功能已禁用": "Chức năng này đã bị khoá",
    '下一个': 'Kế tiếp',
    '24小时内不再出现提示': 'Không còn lời nhắc nào trong ngày',

    // Maintenance
    "系统维护": "Bảo trì hệ thống",
    "维护说明": "BẢO TRÌ",
    "维护开始时间": "Thời gian bắt đầu bảo trì",
    "维护结束时间": "Thời gian cuối bảo trì",
    '维护时间': 'Thời gian bảo trì',

    // TradingHall
    "请输入起始金额": "Số tiền ban đầu",
    "请输入最大金额": "số tiền tối đa",
    "刷新成功": "Làm cho khỏe lại",
    "交易提示": "Gợi ý giao dịch",
    "当前暂无交易数据": "Không có dữ liệu giao dịch cho thời điểm hiện tại",
    "请添加付款方式": "Vui lòng thêm phương thức thanh toán",
    "请添加对应的付款方式": "Vui lòng thêm phương thức thanh toán tương ứng",

    // App
    "你有新的订单状态": "Nhìn vào thứ tự",
    "请立即处理": "Vui lòng xử lý ngay lập tức",
    "付款": "Sự chi trả",
    "选择客服": "Trung tâm hỗ trợ",
    "客服线路1": "Chăm sóc khách hàng 1",
    "客服线路2": "Chăm sóc khách hàng 2",

    // Certification
    "请输入": "Nhập họ tên",
    "身份证号": "Số CCCD",
    "输入身份证号": "Nhập số CCCD",
    "上传身份证": "Tải lên CCCD",
    "人脸面": "Mặt trước",
    "国徽面": "Mặt sau",
    "提交": "nộp",
    "上传异常,请重新上传": "Tải lên bất thường, vui lòng tải lên lại",
    "请输入身份证号": "Vui lòng nhập số nhận dạng",
    "请上传身份证正面": "Vui lòng tải lên mặt trước CMND của bạn",
    "请上传身份证反面": "Vui lòng tải lên mặt sau CMND của bạn",
    "认证信息上传成功，请耐心等待审核": "Đã tải lên thành công, vui lòng kiên nhẫn chờ xem xét",

    // IdentityVerification
    "为了防范身份信息被冒用，保障资金安全，需进行人脸识别，确保是本人操作": "Cần phải nhận dạng khuôn mặt vì lý do an toàn, hãy đảm bảo rằng bạn tự thực hiện việc này",
    "保持光线充足": "giữ cho nó luôn sáng",
    "竖直持握手机，正对镜头": "Hãy đối mặt với máy ảnh",
    "跟随提示做出动作": "Làm theo lời nhắc và hành động",
    "开始识别": "Bắt đầu xác định",
    "身份信息核验":"Xác thực thông tin cá nhân"
}
