import { createRouter, createWebHashHistory } from 'vue-router'
/*
* requireAuth： 是否需要登录
* keepAlive: 是否缓存
* */
const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Home/Home.vue'),
    meta: {keepAlive: true}
  },
  {
    path: '/announcement', // 系统公告
    name: 'Announcement',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Announcement.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/tradingHall', // 卖币
    name: 'TradingHall',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/TradingHall.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/buyCoin', // 买币
    name: 'BuyCoin',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/BuyCoin.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/my', // 个人中心
    name: 'My',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/My.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/personalData', // 个人资料
    name: 'PersonalData',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/PersonalData.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/certification', // 实名认证
    name: 'Certification',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/Certification.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/identityVerification', // 身份信息核验
    name: 'IdentityVerification',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/IdentityVerification.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/helpCenter', // 帮助中心
    name: 'HelpCenter',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/HelpCenter.vue')
  },
  {
    path: '/transfer', // 转账
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/Transfer.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/modifyLoginPassword', // 修改登录密码
    name: 'ModifyLoginPassword',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/ModifyLoginPassword.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/modifyPaymentPassword', // 修改支付密码
    name: 'ModifyPaymentPassword',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/ModifyPaymentPassword.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/deviceManagement', // 修改支付密码
    name: 'DeviceManagement',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/DeviceManagement.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/feedBack', // 意见反馈
    name: 'FeedBack',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/FeedBack.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/personalReport', // 个人报表
    name: 'PersonalReport',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/PersonalReport.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/agencyCommission', // 代理佣金
    name: 'AgencyCommission',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/AgencyCommission.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/fundRecord', // 资金明细
    name: 'FundRecord',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/FundRecord.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/order', // 订单
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Order/Order.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/buyerOrderDetails', // 买家订单详情
    name: 'BuyerOrderDetails',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Order/BuyerOrderDetails.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/sellerOrderDetails', // 买家订单详情
    name: 'SellerOrderDetails',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Order/SellerOrderDetails.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/uploadVoucher', // 上传凭证
    name: 'UploadVoucher',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Order/UploadVoucher.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/maintenance', // 维护界面
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Maintenance.vue')
  },
  {
    path: '/fromThirdPay', // 第三方过来的支付页面
    name: 'FromThirdPay',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/fromThirdPay.vue')
  },
  {
    path: '/fromThirdReg', // 第三方过来的注册页面
    name: 'FromThirdReg',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/fromThirdReg.vue')
  },
  {
    path: '/bannerDetail', // banner详情
    name: 'BannerDetail',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/BannerDetail.vue'),
    meta: {keepAlive: true}
  },
  {
    path: '/quickTrade', // 快捷交易页面
    name: 'QuickTrade',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/QuickTrade/QuickTrade.vue'),
    meta: {keepAlive: false}
  },
  {
    path: '/quickBuyOrderDetails', // 快捷买单详情
    name: 'QuickBuyOrderDetails',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Order/QuickBuyOrderDetails.vue'),
    meta: {keepAlive: true}
  },
  {
    path: '/quickSellOrderDetails', // 快捷卖单详情
    name: 'QuickSellOrderDetails',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/Order/QuickSellOrderDetails.vue'),
    meta: {keepAlive: true}
  },
  {
    path: '/realTimeTrade', // 交易图页面
    name: 'RealTimeTrade',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/RealTimeTrade/RealTimeTrade.vue'),
    meta: {keepAlive: true}
  },
  {
    path: '/scanPay', // 扫码支付
    name: 'ScanPay',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/ScanPay.vue'),
  },
  {
    path: '/recordVideo', // 视频录制
    name: 'RecordVideo',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/My/RecordVideo.vue')
  },
  {
    path: '/checkoutCounter', // 收银台
    name: 'CheckoutCounter',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/CheckoutCounter/CheckoutCounter.vue')
  },
  {
    path: '/checkoutCounterPayStatus', // 交易结束页面
    name: 'CheckoutCounterPayStatus',
    component: () => import(/* webpackChunkName: "about" */ '../viViews/CheckoutCounter/CheckoutCounterPayStatus.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
