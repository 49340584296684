import { createRouter, createWebHashHistory } from 'vue-router'
/*
* requireAuth： 是否需要登录
* keepAlive: 是否缓存
* */
const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {keepAlive: true}
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  // },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  // },
  {
    path: '/announcement', // 系统公告
    name: 'Announcement',
    component: () => import(/* webpackChunkName: "about" */ '../views/Announcement.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/tradingHall', // 卖币
    name: 'TradingHall',
    component: () => import(/* webpackChunkName: "about" */ '../views/TradingHall.vue'),
    meta: {requireAuth: true}
  },
  // {
  //   path: '/sellCoin', // 卖币
  //   name: 'SellCoin',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SellCoin.vue'),
  //   meta: {requireAuth: true}
  // },
  {
    path: '/buyCoin', // 买币
    name: 'BuyCoin',
    component: () => import(/* webpackChunkName: "about" */ '../views/BuyCoin.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/my', // 个人中心
    name: 'My',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/My.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/personalData', // 个人资料
    name: 'PersonalData',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/PersonalData.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/certification', // 实名认证
    name: 'Certification',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/Certification.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/identityVerification', // 身份信息核验
    name: 'IdentityVerification',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/IdentityVerification.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/helpCenter', // 帮助中心
    name: 'HelpCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/HelpCenter.vue')
  },
  // {
  //   path: '/aboutUs', // 关于我们
  //   name: 'AboutUs',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/My/AboutUs.vue'),
  //   meta: {requireAuth: true}
  // },
  {
    path: '/transfer', // 转账
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/Transfer.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/modifyLoginPassword', // 修改登录密码
    name: 'ModifyLoginPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/ModifyLoginPassword.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/modifyPaymentPassword', // 修改支付密码
    name: 'ModifyPaymentPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/ModifyPaymentPassword.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/deviceManagement', // 修改支付密码
    name: 'DeviceManagement',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/DeviceManagement.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/feedBack', // 意见反馈
    name: 'FeedBack',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/FeedBack.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/personalReport', // 个人报表
    name: 'PersonalReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/PersonalReport.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/agencyCommission', // 代理佣金
    name: 'AgencyCommission',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/AgencyCommission.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/fundRecord', // 资金明细
    name: 'FundRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/My/FundRecord.vue'),
    meta: {requireAuth: true}
  },
  // {
  //   path: '/payment', // 收付款
  //   name: 'Payment',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Payment/Payment.vue'),
  //   meta: {requireAuth: true}
  // },
  // {
  //   path: '/addPayment', // 收付款
  //   name: 'AddPayment',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Payment/AddPayment.vue'),
  //   meta: {requireAuth: true}
  // },
  {
    path: '/order', // 订单
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order/Order.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/buyerOrderDetails', // 买家订单详情
    name: 'BuyerOrderDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order/BuyerOrderDetails.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/sellerOrderDetails', // 买家订单详情
    name: 'SellerOrderDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order/SellerOrderDetails.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/uploadVoucher', // 上传凭证
    name: 'UploadVoucher',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order/UploadVoucher.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/maintenance', // 维护界面
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "about" */ '../views/Maintenance.vue')
  },
  {
    path: '/fromThirdPay', // 第三方过来的支付页面
    name: 'FromThirdPay',
    component: () => import(/* webpackChunkName: "about" */ '../views/fromThirdPay.vue')
  },
  {
    path: '/fromThirdReg', // 第三方过来的注册页面
    name: 'FromThirdReg',
    component: () => import(/* webpackChunkName: "about" */ '../views/fromThirdReg.vue')
  },
  // {
  //   path: '/search',
  //   name: 'Search',
  //   component: () => import('../views/Search.vue')
  // },
  // {
  //   path: '/deposit',
  //   name: 'Deposit',
  //   component: () => import('../views/Bitcoin/Deposit.vue')
  // },
  {
    path: '/bannerDetail', // banner详情
    name: 'BannerDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/BannerDetail.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
