export const handleContent = (text) => {
    return text.replace(/\n/g, '<br/>')
}

// 检测设备类型
export const detectOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 检测 ios
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }

    // 检测 Android
    if (/android/i.test(userAgent)) {
        return 'android';
    }

    // 如果未检测到 Android 或 iOS，则返回未知
    return 'unknown';
}

//  数据的从大到小的排序
export const sortArrayDescending = (array, type) => {
    let arr = []
    if (!Array.isArray(array)) {
        arr = Object.values(array)
    } else {
        arr = array
    }
    // 检查数组中的第一个元素类型
    if (typeof arr[0] === 'object' && arr[0] !== null) {
        // 对象数组，按指定属性排序
        return arr.sort((a, b) => {
            if (typeof a[type] === 'string' && typeof b[type] === 'string') {
                return b[type].localeCompare(a[type]);
            } else {
                return b[type] - a[type]
            }
        });
    } else if (typeof arr[0] === 'string') {
        // 字符串数组，按字符串排序
        return arr.sort((a, b) => b.localeCompare(a))
    } else {
        return arr.sort((a, b) => b - a)
    }
}