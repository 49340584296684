import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import './utils/rem'
import './assets/css/reset.css'
import './assets/css/base.less'
import './assets/theme/index.less';
import 'formdata-polyfill'
import { createI18n } from 'vue-i18n'
// import vConsole from 'vconsole'

const app = createApp(App)

// const vcs = new vConsole()
// app.use(vcs)

// 国际化
// 自动导入语言包
const files = require.context('./locales/', false, /\.js$/)

let messages = {}

files.keys().forEach((key, index) => {
    const _key =  key.split('.')[1].split('/')[1]
    const value = files(key).default

    const obj = {
        [`${_key}`]: value
    }

    messages = { ...obj, ...messages }
});

const i18n = createI18n({
    legacy: false,
    locale: language || 'zh',
    messages
})

app.use(i18n)

// 引入vant组件
import {
    Field, Button, NavBar, Icon, SwipeCell, Image as VanImage, Badge, Toast, Uploader,
    Popover, Search, Sticky, CheckboxGroup, Checkbox, Cell, CellGroup, Dialog, Empty, Popup,
    ActionSheet, IndexBar, IndexAnchor, Switch, NoticeBar, Divider, PullRefresh, Tab, Tabs, CountDown,
    Calendar, List, Loading, Overlay, Collapse, CollapseItem, Swipe, SwipeItem, Picker, ShareSheet
} from 'vant'

app.use(Field).use(Button).use(NavBar).use(Icon).use(SwipeCell).use(VanImage).use(Badge)
    .use(Toast).use(Uploader).use(Popover).use(Search).use(Sticky).use(CheckboxGroup)
    .use(Checkbox).use(Cell).use(CellGroup).use(Dialog).use(Empty).use(Popup).use(ActionSheet)
    .use(IndexBar).use(IndexAnchor).use(Switch).use(NoticeBar).use(Divider).use(PullRefresh)
    .use(Tab).use(Tabs).use(CountDown).use(Calendar).use(List).use(Loading).use(Overlay).use(Collapse)
    .use(CollapseItem).use(Swipe).use(SwipeItem).use(Picker).use(ShareSheet)

// 设置全局Toast时间为2秒
Toast.setDefaultOptions({ duration: 1500 });

// 导入极验
require('@/assets/captcha/boc.js');

// 添加全局事件
const windowScrollTop = function() {
    window.scrollTo(0,0)
}

app.provide('$windowScrollTop',windowScrollTop)

// 封装全局长按指令
app.directive('longpress', {
    mounted(el, binding, vNode) {
    //el为长按对象（事件源）
    //binding 事件处理函数

        // 确保提供的表达式是函数，如果不是，将警告传给控制台
        if (typeof binding.value !== 'function') {
            // 获取组件名称
            const compName = vNode.context.name;
            // 将警告传递给控制台
            let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be `;
            if (compName) { warn += `Found in component '${compName}' `}

            console.warn(warn);
        }

        // 定义变量，计时器清空
        let pressTimer = null;

        // 定义函数处理程序start
        // 创建计时器（ 1秒后执行函数 ）
        let start = (e) => {
            //判断事件是否为click，e.button=0，则为左键，如果是click事件，或者不是按的左键（右键有默认行为），则直接return
            if (e.type === 'click' && e.button !== 0) {
                return;
            }
            //判断计时器是否为空
            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    // 执行事件处理函数
                    handler();
                }, 1000)
            }
        }

        // 取消计时器
        let cancel = (e) => {

            // 检查计时器是否有值
            if ( pressTimer !== null ) {
                clearTimeout(pressTimer);
                pressTimer = null;
            }
        }

        // 运行函数
        const handler = (e) => {
            // 执行传递给指令的方法
            binding.value(e)
        }

        // 添加事件监听器
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);

        // 取消计时器
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    }
})

// 登录权限判断
router.beforeEach((to, from, next) => {
    windowScrollTop()
    if (to.matched.some(record => record.meta.requireAuth)){ // 判断该路由是否需要登录权限
        if (store.state.loginInfos.token) { // 判断当前的token是否存在
            next();
        } else {
            store.commit('setLoginRegisterInfo', { flag: true })

            // next({
            //     path: '/login'
            // })
        }
    } else {
        next();
    }
});
app.use(store).use(router).mount('#app')



