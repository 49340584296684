<template>
  <div class="addPayment-wrap">
    <div class="addPayment-main-wrap">
      <div class="title">{{ t('添加收付款方式') }}</div>

      <div class="addPayment-type-box">
        <div :class="{'active' : paymentWayIndex == 3}" class="item-box" @click="handleChangeWay(3)">
          <van-image v-if="locale === 'vi'" :src="themeImgs.commonPaymentIconVn3"/>
          <van-image v-else :src="themeImgs.commonPaymentIcon3"/>
          <span>{{ t('银行卡') }}</span>
        </div>

        <div :class="{'active' : paymentWayIndex == 2}" class="item-box" @click="handleChangeWay(2)">
          <van-image v-if="locale === 'vi'" :src="themeImgs.commonPaymentIconVn2"/>
          <van-image v-else :src="themeImgs.commonPaymentIcon2"/>
          <span>{{ t('微信') }}</span>
        </div>

        <div :class="{'active' : paymentWayIndex == 1}" class="item-box" @click="handleChangeWay(1)">
          <van-image v-if="locale === 'vi'" :src="themeImgs.commonPaymentIconVn1"/>
          <van-image v-else :src="themeImgs.commonPaymentIcon1"/>

          <span>{{ t('支付宝') }}</span>
        </div>
      </div>

      <!--      支付宝-->
      <div v-if="paymentWayIndex == 1" class="alipay-info-wrap common-info-wrap">
        <div class="info-item">
          <span class="label">{{ t('姓名') }}</span>
          <van-field v-model="realname" :readonly="isFromComprehensive" autocomplete="off" :placeholder="t('请输入真实姓名')"/>
        </div>
        <div class="info-item">
          <span class="label">{{ t('支付宝账号') }}</span>
          <van-field v-model="alipayAccount" autocomplete="off" :placeholder="t('请输入支付宝账号')"/>
        </div>
        <div class="info-item" :class="{ 'has-qrcode': codeFileList.length }">
          <span class="label">{{ codeFileList.length ? t('我的收款码') : t('支付宝二维码')}}</span>
          <van-uploader v-model="codeFileList" :after-read="afterUpload" :before-read="beforeUpload" :max-count="1">
            <div class="upload-icon">
              <span>{{ t('上传二维码') }}</span>
              <i v-html="uploadIcon"></i>
            </div>
          </van-uploader>
        </div>
      </div>

<!--      微信-->
      <div v-if="paymentWayIndex == 2" class="wechat-info-wrap common-info-wrap">
        <div class="info-item">
          <span class="label">{{ t('姓名') }}</span>
          <van-field v-model="realname" :readonly="isFromComprehensive" autocomplete="off" :placeholder="t('请输入真实姓名')"/>
        </div>
        <div class="info-item">
          <span class="label">{{ codeFileList.length ? t('我的收款码') : t('上传收款码')}}</span>
          <van-uploader v-model="codeFileList" :after-read="afterUpload" :before-read="beforeUpload" :max-count="1">
            <div class="upload-icon">
              <span>{{ t('上传我的收款码') }}</span>
              <i v-html="uploadIcon"></i>
            </div>
          </van-uploader>
        </div>
      </div>

<!--      银行-->
      <div v-if="paymentWayIndex == 3" class="bank-info-wrap common-info-wrap">
        <div class="info-item">
          <span class="label">{{ t('姓名') }}</span>
          <van-field
              v-model="realname"
              :readonly="isFromComprehensive"
              autocomplete="off"
              :placeholder="t('请输入真实姓名')"
              @blur="handleBlur('realname')"
          />
        </div>
        <div class="info-item">
          <span class="label">{{ t('银行卡号') }}</span>
          <van-field
              v-model="bankCard"
              autocomplete="off"
              maxlength="19"
              :placeholder="t('请输入银行卡卡号')"
              @blur="handleBlur('bankCard')"
          />
        </div>
        <div class="info-item">
          <span class="label">{{ t('选择银行') }}</span>
          <div class="bank-box" @click="showBankList = true">
            <span>{{ bankName.name ? bankName.name : t('请选择银行') }}</span>
            <van-image :src="themeImgs.commonColorArrowDownIcon"/>
          </div>
        </div>
      </div>

      <div class="add-btn-box">
        <van-button :disabled="btnClock" block @click="handleAdd">{{ t('添加') }}</van-button>
      </div>
    </div>

    <!--        <van-action-sheet-->
    <!--            v-model:show="showBankAction"-->
    <!--            :actions="bankActions"-->
    <!--            @select="handleSelectBankAction"-->
    <!--        >-->
    <!--            <template #description>-->
    <!--                <div class="searchBank">-->
    <!--                    <van-field-->
    <!--                            v-model="search"-->
    <!--                            input-align="center"-->
    <!--                            placeholder="请选择银行"-->
    <!--                    />-->
    <!--                    <van-button type="primary" @click="onConfirm">-->
    <!--                        {{ search ? '完成' : '搜索' }}-->
    <!--                    </van-button>-->
    <!--                </div>-->
    <!--            </template>-->
    <!--        </van-action-sheet>-->

    <van-overlay :show="showOverlay" class-name="addPayment-overlay">
      <van-loading color="#fff" size="24px" type="spinner" vertical>{{ t('上传中') }}...</van-loading>
    </van-overlay>

    <van-popup
        v-model:show="showBankList"
        position="bottom"
        round
        teleport="body"
        :style="{ height: '80%', overflow: 'hidden' }"
    >
      <BankList @setSelectedBank="setSelectedBank" />
    </van-popup>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, defineEmits} from 'vue'
import {doAddPayment, uploadImage} from '@/api/api'
import {Toast} from 'vant'
import {useRoute} from "vue-router"
import {useStore} from 'vuex'
import { uploadIcon } from '@/assets/images/svg'
import BankList from './BankList'
import { useI18n } from 'vue-i18n'
import bus from '@/utils/mitt'

const { t, locale } = useI18n()

const emit = defineEmits(['update:showAdd'])
const route = useRoute()
const store = useStore()
//  银行卡搜索
// const search = ref('')
//  保存银行卡完整数据
// const searchResults = ref([])

//  监听查询字段
// watch(search, (newValue) => {
//   const data = fuzzySearch(newValue)
//   bankActions.value = data
// })

// 点击导航栏左侧返回键/取消按钮
const onClickLeft = () => {
  history.back()
}
// 默认付款方式索引值
const paymentWayIndex = ref(3)

// 选择收付款方式,并重置所有
const handleChangeWay = (index) => {
  paymentWayIndex.value = index
  if (!window.parent.frames['agIframe']) {
    realname.value = ''
  }
  alipayAccount.value = ''
  codeFileList.value = []
  bankCard.value = ''
}
// 是否显示银行卡actions
// const showBankAction = ref(false)
// 银行卡actions
// const bankActions = ref([]);
// 获取银行列表数据
// const handleGetBankList = () => {
//     getBankList().then(res => {
//         if(res.result == 200000) {
//             Object.getOwnPropertyNames(res.data).forEach(function(key){
//                 let obj = {}
//                 obj.name = res.data[key]
//                 obj.id = key
//                 bankActions.value.push(obj)
//                 searchResults.value.push(obj)
//             })
//         }
//     })
// }
// 判断是否从综合那接收真实姓名
const isFromComprehensive = ref(false)
const handleJudgeFromComprehensive = () => {
  if (window.parent.frames['agIframe']) {
    if (store.state.actualName) {
      realname.value = store.state.actualName
      isFromComprehensive.value = true
    }
  } else {
    realname.value = ''
    isFromComprehensive.value = false
  }
}
// 真实姓名
const realname = ref('')
// 支付宝账号
const alipayAccount = ref('')
// 银行卡号
const bankCard = ref('')
// 银行名称
const bankName = ref({
  name: '',
  id: ''
})

onMounted(() => {
  // handleGetBankList()
  handleJudgeFromComprehensive()

  // if (route.query.hasOwnProperty('search')) {
  //   const bankData = JSON.parse(route.query.search)
  //   realname.value = localStorage.getItem('realname')
  //   bankCard.value = localStorage.getItem('bankCard')
  //   bankName.value = bankData
  //   paymentWayIndex.value = parseInt(route.query.currentIndex)
  // }
})

const setSelectedBank = (item) => {
  showBankList.value = false
  realname.value = localStorage.getItem('realname')
  bankCard.value = localStorage.getItem('bankCard')
  bankName.value = item
}

// 选择银行
// const handleSelectBankAction = (item) => {
//     bankName.value = item
//     // showBankAction.value = false
// }

// 只能输入中文
const formatter = (value) => value.replace(/[^\u4e00-\u9fa5]/g, "");
// 存储上传收款码得到的文件流
const codeFileList = ref([])
// 存储上传收款码得到的图片地址
const codeUrl = ref('')
// 上传收款码前
const beforeUpload = () => {
  showOverlay.value = true
  codeFileList.value = []
  codeUrl.value = ''
  return true
}
// 上传收款码后
const afterUpload = (file) => {
  // 此时可以自行将文件上传至服务器
  const formData = new FormData()
  formData.append('file', file.file)
  formData.append('file_name', 'collection')
  uploadImage(formData).then(res => {
    if (res.result == 200000) {
      codeUrl.value = res.data.path
      file.status = 'success'
      showOverlay.value = false
    } else {
      file.status = 'failed';
      file.message = t('上传失败');
      showOverlay.value = false
      codeFileList.value = []
      Toast.fail(res.msg)
    }
  })
};
// 是否显示蒙层
const showOverlay = ref(false)
// 添加收付款方式
const handleAdd = () => {
  if (btnClock.value) return
  if (!realname.value) {
    return Toast.fail(t('请输入真实姓名'))
  }
  if (paymentWayIndex.value == 1) {
    if (!alipayAccount.value) {
      return Toast.fail(t('请输入支付宝账号'))
    }
    if  (!codeUrl.value) {
      return Toast.fail('Mã QR hoặc tên ngân hàng không được để trống')
    }
  }
  if (paymentWayIndex.value == 2) {
    if  (!codeUrl.value) {
      return Toast.fail('Mã QR hoặc tên ngân hàng không được để trống')
    }
  }
  if (paymentWayIndex.value == 3) {
    console.log('bankName', bankName.value)
    if (!bankCard.value) {
      return Toast.fail(t('请输入银行卡卡号'))
    } else if (!bankName.value.name) {
      return Toast.fail(t('请选择银行'))
    }
  }
  btnClock.value = true
  let params = {
    type: paymentWayIndex.value,
    name: realname.value,
    card: "",
    bank_name: "",
    status: 1
  }
  if (paymentWayIndex.value == 1) {
    params.card = alipayAccount.value
  }
  if (paymentWayIndex.value == 3) {
    params.card = bankCard.value
    params.bank_name = bankName.value.id
  }
  if (paymentWayIndex.value != 3) {
    params.bank_name = codeUrl.value
  }
  doAddPayment(params).then(res => {
    btnClock.value = false
    if (res.result == 200000) {
      Toast.success(t('添加成功'))
      closeOpenAdd(false)
      bus.emit('getMyPaymentList')
    } else {
      Toast.fail(res.msg)
    }
  }).catch(error => {
    btnClock.value = false
    Toast.fail(t('操作失败，请检查网络重试或者联系客服'))
  })
}
// 按钮锁
const btnClock = ref(false)
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

//  失焦时，保存用户名和银行卡账号
const handleBlur = (type) => {
  switch (type) {
    case 'realname':
      localStorage.setItem('realname', realname.value)
      break
    case 'bankCard':
      localStorage.setItem('bankCard', bankCard.value)
      break
  }
}

const closeOpenAdd = (flag) => {
  emit('update:showAdd', flag)
}

const showBankList = ref(false)
</script>

<style lang="less" scoped>
.addPayment-wrap {
  .addPayment-main-wrap {
    //height: calc(100vh - 46px);
    padding: 0 15px;
    //background: var(--mainBgColor);

    .title {
      padding: 18px 0 27px 0;
      font-family: PingFang SC;
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      //color: var(--textCommonColor);
      color: #000;
    }

    .addPayment-type-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 50px;
      margin-bottom: 11px;
      //background: var(--boxBgColor);
      background: rgba(244, 244, 244, .6);
      border-radius: 8px;

      .item-box {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 84px;
        height: 38px;
        //color: var(--textCommonColor);
        color: #000;

        &.active {
          background: rgba(255, 255, 255, 0.3);
          //border: 1px solid var(--textColor);
          border: 1px solid #2EBD85;
          border-radius: 30px;

          span {
            //color: var(--textColor);
            color: #9C5E5C;
          }
        }

        .van-image {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }

    .common-info-wrap {
      padding: 16px 9px 24px;
      border-radius: 8px;
      //background: var(--boxBgColor);
      background: rgba(244, 244, 244, .6);

      .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &.has-qrcode {
          align-items: start;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        span {
          font-size: 16px;
          //color: var(--textCommonColor);

          &.label {
            width: 100px;
            margin-right: 4px;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: #000;
          }
        }

        .upload-icon {
          display: flex;
          align-items: center;
          color: #2EBD85;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;

          i {
            width: 24px;
            height: 24px;
            margin-left: 10px;

            ::v-deep svg {
              width: 100%;
              height: 100%;

              circle {
                fill: rgba(72, 197, 148, .4);
              }
            }

          }
        }

        :deep(.van-field) {
          flex: 1;
          padding: 0;
          background: inherit;
          border-radius: 8px;
        }

        ::v-deep {
          .van-uploader__preview-image {
            width: 158px;
            height: 158px;
            border-radius: 8px;
          }
        }

        .bank-box {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 184px;
          height: 38px;
          background: rgba(255, 255, 255, 0.3);
          //border: 1px solid var(--textColor);
          border: 1px solid #2EBD85;
          border-radius: 30px;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          color: #000;

          span {
            display: inline-block;
            width: 100%;
            padding: 0 0 0 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .van-image {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            margin-right: 24px;
          }
        }
      }
    }

    .add-btn-box {
      height: 44px;
      margin-top: 20px;
      border-radius: 8px;

      .van-button {
        border-radius: 8px;
        background: #2EBD85;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
        color: #FFF;

      }
    }
  }

  .addPayment-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/deep/ .van-action-sheet__description {
  border-bottom: 5px solid #F3F4F7;
  position: sticky;
  top: 0;
}

/deep/ .van-action-sheet {
  height: 300px;
}

.searchBank {
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;

  .van-cell {
    background: transparent;
    position: absolute;
  }

  :deep {
    .van-button {
      background: transparent;
      padding: 0;
      color: #535C66;
      position: absolute;
      right: 15px;
    }
  }
}
</style>
