<template>
  <van-dialog v-model:show="showFlag"
              @closed="closeDialog"
              class="payment-dialog"
              :show-confirm-button="false"
              :close-on-click-overlay="true"
  >
    <van-image class="close-icon" :src="themeImgs.commonCloseIcon" @click="store.commit('setPaymentDialogFlag', false)" />
    <Payment v-if="!showAdd" v-model:showAdd="showAdd"/>
    <AddPayment v-if="showAdd" v-model:showAdd="showAdd" />
  </van-dialog>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import { useStore } from  'vuex'
import Payment from "./components/Payment";
import AddPayment from "./components/AddPayment";

// defineOptions({
//   name: 'PaymentIndex'
// })

const store = useStore()
const showFlag = ref(false)

watch(() => store.state.paymentDialogFlag, val => {
  showFlag.value = val
})

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const showAdd = ref(false)

const closeDialog = () => {
  store.commit('setPaymentDialogFlag', false)
  showAdd.value = false
}
</script>

<style lang='less' scoped>
.close-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1000;
  width: 24px;
  height: 24px;
}
</style>

<style lang='less'>
.payment-dialog {
  width: 327px;
  padding-bottom: 33px;
}
</style>
