export default {
    // 公共
    "请求超时": "请求超时",
    "请求超时服务器链接失败": "请求超时服务器链接失败",

    // components Geet
    "行为验证™ 组件加载中": "行为验证™ 组件加载中",
    "点击进行验证": "点击进行验证",
    "验证成功": "验证成功",

    // sideBar
    "您还未登录": "您还未登录",
    "登录": "登录",
    "注册": "注册",
    "后查看": "后查看",
    "钱包地址": "钱包地址",
    "未实名": "未实名",
    "已实名": "已实名",
    "审核中": "审核中",
    "复制成功": "复制成功",
    "实名认证": "实名认证",
    "扫一扫": "扫一扫",
    "交易订单": "交易订单",
    "收款信息": "收款信息",
    "资金明细": "资金明细",
    "个人报表": "个人报表",
    "代理佣金": "代理佣金",
    "关于我们": "关于我们",
    "帮助中心": "帮助中心",
    "在线客服": "在线客服",
    "修改登录密码": "修改登录密码",
    "修改支付密码": "修改支付密码",
    "退出登录": "退出登录",
    "退出成功": "退出成功",

    // sellCoin
    "输入出售数量": "输入出售数量",
    "可售余额": "可售余额",
    "单次出售最小数量": "单次出售最小数量",
    "收款方式": "收款方式",
    "出售方式": "出售方式",
    "不拆分": "不拆分",
    "拆分": "拆分",
    "出售": "出售",
    "请输入支付密码": "请输入支付密码",
    "支付密码": "支付密码",
    "提示": "提示",
    "请先添加收付款方式": "请先添加收付款方式",
    "请输入出售数量": "请输入出售数量",
    "出售数量不能小于单次最小数量": "出售数量不能小于单次最小数量",
    "请选择收款方式": "请选择收款方式",
    "上架成功": "上架成功",
    "操作失败，请检查网络重试或者联系客服": "操作失败，请检查网络重试或者联系客服",
    "RMB": "RMB",
    // Usdt
    "当前余额": "当前余额",
    "币": "币",
    "类型": "类型",
    "请选择USDT类型": "请选择USDT类型",
    "链路": "链路",
    "请选择USDT链路": "请选择USDT链路",
    "数量": "数量",
    "请输入要充值的USDT数量": "请输入要充值的USDT数量",
    "请输入要提现的USDT数量": "请输入要提现的USDT数量",
    "地址": "地址",
    "请选择USDT地址": "请选择USDT地址",
    "请输入提现的USDT-TRC20地址": "请输入提现的USDT-TRC20地址",
    "密码": "密码",
    "注意": "注意",
    "转账时请核对收款地址是否一致，避免造成无法追回损失!": "转账时请核对收款地址是否一致，避免造成无法追回损失!",
    "填写地址时请再次核实是否正确，避免造成无法追回损失！": "填写地址时请再次核实是否正确，避免造成无法追回损失!",
    "支付凭证": "支付凭证",
    "上传支付凭证": "上传支付凭证",
    "提交充值": "提交充值",
    "提交提现": "提交提现",
    "暂无数据": "暂无数据",
    "完成": "完成",
    "上传中": "上传中",
    "USDT地址已复制": "USDT地址已复制",
    "操作失败": "操作失败",
    "当前账户余额不足": "当前账户余额不足",
    "请输入USDT数量": "请输入USDT数量",
    "充值的USDT数量小于最低配置限制": "充值的USDT数量小于最低配置限制",
    "提现的USDT数量小于最低配置限度": "提现的USDT数量小于最低配置限度",
    "USDT地址只能包含英文和数字": "USDT地址只能包含英文和数字",
    "支付密码不能为空": "支付密码不能为空",
    "报价": "报价",

    // Deposit
    "充值": "充值",

    // login
    "手机号/账号": "手机号/账号",
    "请输入手机号或账号": "请输入手机号或账号",
    "下一步": "下一步",
    "创建一个账户": "创建一个{0}账户",
    "请输入您的密码": "请输入您的密码",
    "记住密码": "记住密码",
    "联系客服": "联系客服",
    "输入验证码": "输入验证码",
    "确定": "确定",
    "请输入密码": "请输入密码",
    "请输入验证码": "请输入验证码",
    "请点击进行验证": "请点击进行验证",
    "登录成功": "登录成功",
    "账户": "账户",

    // Register
    "创建账户": "创建账户",
    "账号": "账号",
    "手机号": "手机号",
    "已有账号，去登录": "已有账号，去登录",
    "设置登录与支付密码": "设置登录与支付密码",
    "验证码已发送": "验证码已发送",
    "请输入短信验证码": "请输入短信验证码",
    "重新获取": "重新获取",
    "请输入登录密码": "请输入登录密码",
    "请输入数字与英文组合至少6位": "请输入数字与英文组合至少6位",
    "请确认登录密码": "请确认登录密码",
    "请保持登录密码一致": "请保持登录密码一致",
    "请输入手机号码": "请输入手机号码",
    "发送中": "发送中",
    "发送成功": "发送成功",
    "请输入用户名": "请输入用户名",
    "请再次确认密码": "请再次确认密码",
    "两次密码不一致": "两次密码不一致",
    "请设置支付密码": "请设置支付密码",
    "注册成功": "注册成功",
    "请输入账号": "请输入账号",
    "请设置6位纯数字支付密码": "请设置6位纯数字支付密码",

    //AgencyCommission
    "全部": "全部",
    "买单佣金": "买单佣金",
    "卖单佣金": "卖单佣金",
    "至": "至",
    "请输入搜索的订单编号": "请输入搜索的订单编号",
    "搜索": "搜索",
    "已派发佣金": "已派发佣金",
    "订单号": "订单号",
    "返佣账号": "返佣账号",
    "交易金额": "交易金额",
    "买卖类型": "买卖类型",
    "返佣比例": "返佣比例",
    "返佣金额": "返佣金额",
    "返佣方式": "返佣方式",
    "返佣时间": "返佣时间",
    "返佣派发": "返佣派发",
    "没有更多了": "没有更多了",
    "买单": "买单",
    "卖单": "卖单",
    "日结": "日结",
    "周结": "周结",
    "月结": "月结",
    "已返佣": "已返佣",
    "未返佣": "未返佣",
    "进行中": "进行中",
    "已完成": "已完成",
    "已取消": "已取消",
    "人工退回": "人工退回",
    "人工打币": "人工打币",
    "出售中": "出售中",
    "已冻结": "已冻结",
    "交易中": "交易中",
    "待支付": "待支付",
    "成功": "成功",
    "失败": "失败",

    // FundRecord
    "取消": "取消",
    "人工加款": "人工加款",
    "人工扣款": "人工扣款",
    "买币-成功": "买币-成功",
    "上架货币": "上架货币",
    "上架货币强制退回": "上架货币强制退回",
    "卖币成功": "卖币成功",
    "api充值": "api充值",
    "api提现": "api提现",
    "佣金": "佣金",
    "用户转账": "用户转账",
    "用户收款": "用户收款",
    "代理转账": "代理转账",
    "代理收款": "代理收款",
    "USDT充值": "USDT充值",
    "USDT提现": "USDT提现",
    "USDT提现退回": "USDT提现退回",

    // HelpCenter
    "买币": "买币",
    "卖币": "卖币",
    "提现": "提现",
    "快捷买币": "快捷买币",

    // ModifyLoginPassword
    "旧密码": "旧密码",
    "请输入旧密码": "请输入旧密码",
    "新密码": "新密码",
    "请输入新密码": "请输入新密码",
    "确认密码": "确认密码",
    "确认": "确认",
    "请再次输入新密码": "请再次输入新密码",
    "两次新密码不一致": "两次新密码不一致",
    "修改成功": "修改成功",

    // ModifyPaymentPassword
    "旧支付密码": "旧支付密码",
    "新支付密码": "新支付密码",
    "确认支付密码": "确认支付密码",

    // PersonalData
    "个人资料": "个人资料",
    "设置头像": "设置头像",
    "修改昵称": "修改昵称",
    "请输入新的昵称": "请输入新的昵称",

    // PersonalReport
    "买单总额": "买单总额",
    "卖单总额": "卖单总额",
    "API充值总额": "API充值总额",
    "API充值返点": "API充值返点",
    "API提现总额": "API提现总额",
    "交易纠纷订单总额": "交易纠纷订单总额",
    "转账总额": "转账总额",
    "收款总额": "收款总额",

    // BuyerOrderDetails
    "订单详情": "订单详情",
    "发起订单": "发起订单",
    "卖家确认": "卖家确认",
    "凭证已上传": "凭证已上传",
    "卖家已打币": "卖家已打币",
    "卖家已暂停": "卖家已暂停",
    "订单编号": "订单编号",
    "买家名字": "买家名字",
    "买家昵称": "买家昵称",
    "金额": "金额",
    "订单时间": "订单时间",
    "付款方式": "付款方式",
    "支付宝": "支付宝",
    "微信": "微信",
    "银行卡": "银行卡",
    "开户行": "开户行",
    "银行卡号": "银行卡号",
    "支付剩余时间": "支付剩余时间",
    "卖家名字": "卖家名字",
    "卖家昵称": "卖家昵称",
    "取消订单": "取消订单",
    "上传凭证": "上传凭证",
    "买家上传支付凭证": "买家上传支付凭证",
    "已转账，等待卖方确认": "已转账，等待卖方确认",
    "查看凭证": "查看凭证",
    "创建时间": "创建时间",
    "取消时间": "取消时间",
    "订单已取消": "订单已取消",
    "撤单备注": "撤单备注",
    "冻结时间": "冻结时间",
    "订单已冻结": "订单已冻结",
    "卖家暂停打币": "卖家暂停打币",
    "卖家收款二维码": "卖家收款二维码",
    "温馨提示": "温馨提示",
    "买家付款凭证": "买家付款凭证",
    "是否撤单": "是否撤单",
    "撤单原因": "撤单原因",
    "撤单原因可为空": "撤单原因可为空",
    "撤单成功": "撤单成功",
    "点击扫码付款": "点击扫码付款",

    // Order
    "订单": "订单",
    "卖币订单":"卖币订单",
    "挂单": "挂单",
    "API订单": "API订单",
    "加款": "加款",
    "扣款": "扣款",
    "挂单编号": "挂单编号",
    "下架": "下架",
    "总出售": "总出售",
    "失败原因": "失败原因",
    "系统冻结": "系统冻结",
    "处理中": "处理中",
    "拒绝": "拒绝",
    "确认将该笔订单进行下架吗": "确认将该笔订单进行下架吗",
    "下架成功": "下架成功",

    // SellerOrderDetails
    "卖单详情": "卖单详情",
    "购买数量": "购买数量",
    "撤销": "撤销",
    "等待买家付款": "等待买家付款",
    "暂停打币": "暂停打币",
    "确认打币": "确认打币",
    "买家已付款": "买家已付款",
    "请核实资金到账后再确认打币，切勿未收到转账直接打币造成损失。": "请核实资金到账后再确认打币，切勿未收到转账直接打币造成损失。",
    "暂停打币成功": "暂停打币成功",
    "打币成功": "打币成功",

    // UploadVoucher
    "我已转账": "我已转账",
    "上传成功": "上传成功",

    // AddPayment
    "添加收付款方式": "添加收付款方式",
    "姓名": "姓名",
    "请输入真实姓名": "请输入真实姓名",
    "支付宝账号": "支付宝账号",
    "请输入支付宝账号": "请输入支付宝账号",
    "我的收款码": "我的收款码",
    "支付宝二维码": "支付宝二维码",
    "上传二维码": "上传二维码",
    "上传收款码": "上传收款码",
    "上传我的收款码": "上传我的收款码",
    "请输入银行卡卡号": "请输入银行卡卡号",
    "选择银行": "选择银行",
    "请选择银行": "请选择银行",
    "添加": "添加",
    "上传失败": "上传失败",
    "添加成功": "添加成功",

    // BankList
    "请输入搜索的银行": "请输入搜索的银行",

    // Payment
    "加载中": "加载中",
    "添加收款信息": "添加收款信息",
    "支付宝名": "支付宝名",
    "查看二维码": "查看二维码",
    "禁用": "禁用",
    "系统禁用": "系统禁用",
    "微信名": "微信名",
    "银行名": "银行名",
    "二维码": "二维码",

    // Announcement
    "站内信息": "站内信息",
    "公告内容": "公告内容",

    // BannerDetail
    "Banner详情": "Banner详情",

    // BuyCoin
    "购买": "购买",
    "输入购买数量": "输入购买数量",
    "可购总数": "可购总数",
    "最小购买数量": "最小购买数量",
    "卖家": "卖家",
    "出售数量": "出售数量",
    "可拆售": "可拆售",
    "不可拆售": "不可拆售",
    "支付": "支付",
    "请输入购买数量": "请输入购买数量",
    "购买数量不能小于最小数量": "购买数量不能小于最小数量",
    "请按照订单金额支付": "请按照订单金额支付",

    // fromThirdPay
    "充值金额": "充值金额",
    "订单时效": "订单时效",
    "pay余额": "pay余额",
    "确认支付": "确认支付",
    "获得更多": "获得更多",
    "去充值": "去充值",
    "您的余额不足，请充值": "您的余额不足，请充值",
    "支付成功": "支付成功",

    // fromThirdReg
    "初次使用设置": "初次使用设置",
    "设置账号": "设置账号",
    "首字母开头-6-20位": "首字母开头-6-20位",
    "真实姓名": "真实姓名",
    "输入真实姓名": "输入真实姓名",
    "设置登录密码": "设置登录密码",
    "设置支付密码": "设置支付密码",
    "6位数字支付密码": "6位数字支付密码",
    "请设置登录密码": "请设置登录密码",
    "设置成功": "设置成功",

    // Home
    "暂无公告": "暂无公告",
    "回到充值页": "回到充值页",
    "账号被冻结": "账号被冻结",
    "请先进行实名认证": "请先进行实名认证",
    "实名审核中": "实名审核中",
    "该功能已禁用": "该功能已禁用",
    '下一个': '下一个',
    '24小时内不再出现提示': '24小时内不再出现提示',

    // Maintenance
    "系统维护": "系统维护",
    "维护说明": "维护说明",
    "维护开始时间": "维护开始时间",
    "维护结束时间": "维护结束时间",
    '维护时间': '维护时间',

    // TradingHall
    "请输入起始金额": "请输入起始金额",
    "请输入最大金额": "请输入最大金额",
    "刷新成功": "刷新成功",
    "交易提示": "交易提示",
    "当前暂无交易数据": "当前暂无交易数据",
    "请添加付款方式": "请添加付款方式",
    "请添加对应的付款方式": "请添加对应的付款方式",

    // App
    "你有新的订单状态": "你有新的订单状态",
    "请立即处理": "请立即处理",
    "付款": "付款",
    "选择客服": "选择客服",
    "客服线路1": "客服线路1",
    "客服线路2": "客服线路2",

    // Certification
    "请输入": "请输入",
    "身份证号": "身份证号",
    "输入身份证号": "请输入身份证号",
    "上传身份证": "上传身份证",
    "人脸面": "人脸面",
    "国徽面": "国徽面",
    "提交": "提交",
    "上传异常,请重新上传": "上传异常,请重新上传",
    "请输入身份证号": "请输入身份证号",
    "请上传身份证正面": "请上传身份证正面",
    "请上传身份证反面": "请上传身份证反面",
    "认证信息上传成功，请耐心等待审核": "认证信息上传成功，请耐心等待审核",

    // IdentityVerification
    "为了防范身份信息被冒用，保障资金安全，需进行人脸识别，确保是本人操作": "为了防范身份信息被冒用，保障资金安全，需进行人脸识别，确保是本人操作",
    "保持光线充足": "保持光线充足",
    "竖直持握手机，正对镜头": "竖直持握手机，正对镜头",
    "跟随提示做出动作": "跟随提示做出动作",
    "开始识别": "开始识别",
    "身份信息核验":"身份信息核验"
}
