<template>
  <div class="register-wrap">
    <div class="register-out-box">
      <div v-if="step === 1" class="register-box">
        <div class="title">{{ t('创建账户') }}</div>

        <template v-if="showUsernameReg">
          <div class="sub-title">{{ t('账号') }}</div>

          <van-field
              v-model="regParams.username"
              :formatter="formatter"
              autocomplete="off"
              center
              clearable
              :placeholder="t('请输入账号')"
          />

          <div class="btn" @click="nextStep">{{ t('下一步') }}</div>
        </template>

        <template v-else>
          <div class="sub-title">{{ t('手机号') }}</div>

          <van-field
              v-model="SMSCodeParams.username"
              :formatter="formatter"
              autocomplete="off"
              center
              clearable
              :placeholder="t('请输入手机号码')"
          />
          <div class="btn" @click="phoneNextStep">{{ t('下一步') }}</div>
        </template>

        <div class="go-login" @click="closeRegister">{{ t('已有账号，去登录') }}</div>
      </div>

      <div class="register-box" v-if="step === 2">
        <van-icon name="arrow-left" class="last-step" @click="step -= 1" />

        <div class="title">{{ t('设置登录与支付密码') }}</div>

        <template v-if="!showUsernameReg">
          <div class="verify-code-title">{{ t('验证码已发送') }}
            <van-image :src="themeImgs.commonCsYesIcon"/>
          </div>

          <van-field
              v-model="regParams.code"
              :formatter="formatter"
              autocomplete="off"
              center
              clearable
              maxlength="6"
              :placeholder="t('请输入短信验证码')"
              type="number"
          >
            <template #button>
              <div class="count-down-wrapper" @click="changePassWordType">
                <span class="time" v-show="isCountDownFinish" @click="showVerifyCode = true">{{ t('重新获取') }}</span>

                <van-count-down  ref="myCountDown"
                                 @finish="countDownFinish"
                                 :time="60 * 1000"
                                 v-show="!isCountDownFinish"
                >
                  <template #default="timeData">
                    <span class="time">{{ timeData.seconds }}s</span>
                  </template>
                </van-count-down>
              </div>
            </template>
          </van-field>
        </template>

        <div class="sub-title">{{ t('请输入登录密码') }}</div>
        <van-field
            v-model="regParams.password"
            :formatter="formatter"
            autocomplete="off"
            center
            clearable
            :placeholder="t('请输入数字与英文组合至少6位')"
            type="password"
        />
        <!--        防止自动填充-->
        <input type="password" hidden autocomplete="new-password" />

        <div class="sub-title">{{ t('请确认登录密码') }}</div>
        <van-field
            v-model="regParams.confirm_password"
            :formatter="formatter"
            autocomplete="off"
            center
            clearable
            :placeholder="t('请保持登录密码一致')"
            type="password"
        />
        <!--        防止自动填充-->
        <input type="password" hidden autocomplete="new-password" />

        <div class="sub-title">{{ t('请输入支付密码') }}</div>
        <van-field
            v-model="regParams.pay_password"
            :formatter="formatter"
            autocomplete="off"
            center
            clearable
            maxlength="6"
            :placeholder="t('请设置6位纯数字支付密码')"
            type="password"
        />

        <!--        防止自动填充-->
        <input type="password" hidden autocomplete="new-password" />

        <div v-if="showUsernameReg" class="btn" @click="showVerifyCode = true">{{ t('注册') }}</div>
        <div v-else class="btn" @click="handleRegister">{{ t('注册') }}</div>
      </div>

    </div>

    <div class="service-forget" @click="setShowServicePopup(true)">
      <van-image :src="themeImgs.loginRegCsIcon" />{{ t('联系客服') }}
    </div>

<!--    验证码弹框-->
    <van-dialog v-model:show="showVerifyCode"
                :show-confirm-button="false"
                :close-on-click-overlay="true"
                class="verify-code-dialog"
    >
      <van-image class="close-icon" :src="themeImgs.loginRegCloseIcon" @click="showVerifyCode = false" />

      <template v-if="codeType==1">
        <div class="label">{{ t('输入验证码') }}</div>
        <div class="wrapper">
          <van-field
              v-if="showUsernameReg"
              v-model="regParams.code"
              :formatter="formatter"
              autocomplete="off"
              center
              maxlength="4"
          >
          </van-field>

          <van-field
              v-if="!showUsernameReg"
              v-model="SMSCodeParams.code"
              :formatter="formatter"
              autocomplete="off"
              center
              maxlength="4"
          >
          </van-field>

          <van-image :src="graphicCodeImg" class="code-img" @click="handleGetGraphicCode"/>
        </div>

      </template>

      <div v-show="codeType==3">
        <div class="label">{{ t('点击进行验证') }}</div>
        <Geet ref="regGeetRef"
              @handleGetGeetSession="handleGetGeetSession"
              @handleUpdateGeetStatus="handleUpdateGeetStatus"
        />
      </div>

      <div v-if="!showUsernameReg" class="btn" @click="getSmsMsgAgain">{{ t('确定') }}</div>
      <div v-else class="btn" @click="handleRegister">{{ t('确定') }}</div>
    </van-dialog>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, reactive, ref, watch, onMounted} from "vue";
import {useStore} from "vuex";
import {Icon, Toast} from "vant";
import {doRegister, getCodeType, getGraphicCode, getLoginRegSys, getVerification} from "@/api/api";
import wsConnection from "@/utils/websocket"
import Geet from "@/components/Geet"
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  show: {
    type: Boolean
  }
})

const emit = defineEmits(['update:showRegister'])

const store = useStore();
const regGeetRef = ref()
// 默认行为验证码状态
const geetStatus = ref(1)
// 改变行为验证码状态
const handleUpdateGeetStatus = val => {
  geetStatus.value = val
}
// 存储验证码方式
const codeType = ref('')
// 获取验证码方式
const handleGetCodeType = () => {
  getCodeType().then(res => {
    if (res.result == 200000) {
      codeType.value = res.data.type
      if (res.data.type == 1) {
        handleGetGraphicCode()
      } else {
        regGeetRef.value.init(res.data)
        SMSCodeParams.session = res.data.session_id
        regParams.session = res.data.session_id
      }
    }
  })
}

// 获取极验证session
const handleGetGeetSession = () => {
  handleGetCodeType()
}

// 存储图形验证码
const graphicCodeImg = ref('')

// 获取图形验证码
const handleGetGraphicCode = () => {
  getGraphicCode().then(res => {
    if (res.result == 200000) {
      graphicCodeImg.value = res.data.captcha
      SMSCodeParams.session = res.data.session
      regParams.session = res.data.session
    }
  })
}
// 获取登录验证配置
const handleGetLoginRegSys = () => {
  getLoginRegSys().then(res => {
    if (res.result == 200000) {
      if (res.data.info.register == 1) {
        showUsernameReg.value = false
      } else {
        showUsernameReg.value = true
      }
      // handleGetCodeType()
    }
  })
}

onMounted(() => {
  handleGetLoginRegSys()
})

// 过滤输入的中文
const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
const inputType = ref("password");
const showEye = ref(true);
const SMSCodeParams = reactive({
  username: "", // 用户名
  code: "", // 图形验证码密码
  session: ""
});

const regParams = reactive({
  username: "", // 用户名
  password: "", // 密码
  confirm_password: "", // 确认密码
  pay_password: "", // 支付密码
  code: "", // 短信/邮箱验证码
  session: '',
  is_captcha: 1
});

// 显示密码
const changePassWordType = () => {
  showEye.value = !showEye.value;
  if (showEye.value) {
    inputType.value = "password";
  } else {
    inputType.value = "text";
  }
};

const myCountDown = ref(null)

const getSmsMsgAgain = () => {
  if (myCountDown.value) {
    myCountDown.value.reset();
    myCountDown.value.start();
  }

  handleClickSMSCodeBtn()
}
// 点击按钮获取短信验证码
const handleClickSMSCodeBtn = () => {
  if (!SMSCodeParams.username) {
    return Toast.fail(t('请输入手机号码'));
  }

  if (codeType.value == 1 && !SMSCodeParams.code) {
    return Toast.fail(t('请输入验证码'));
  }

  if (codeType.value == 3 && geetStatus.value != 4) {
    return Toast.fail(t('请点击进行验证'));
  }

  handleGetSMSCode()
}

const showVerifyCode = ref(false)

const step = ref(1) // 注册步骤

// 获取短信验证码
const handleGetSMSCode = () => {
  Toast.loading({
    message: t('发送中') + '...',
    forbidClick: true,
  });
  getVerification(SMSCodeParams).then(res => {
    Toast.clear()
    if (res.result == 200000) {
      Toast.success(t('发送成功'));
      showRegPage.value = true
      regParams.username = SMSCodeParams.username
      step.value = 2
      showVerifyCode.value = false
    } else {
      // 充值验证码
      handleGetCodeType()
      Toast.fail(res.msg);
    }
  })
}
// 显示第二注册页
const showRegPage = ref(false)
// 点击注册
const handleRegister = () => {
  if (!regParams.username) {
    return Toast.fail(t('请输入用户名'));
  }

  if (!regParams.code && !showUsernameReg.value) {
    return Toast.fail(t('请输入短信验证码'));
  }

  if (!regParams.password) {
    return Toast.fail(t('请输入密码'));
  }

  if (!regParams.confirm_password) {
    return Toast.fail(t('请再次确认密码'));
  }

  if (regParams.password != regParams.confirm_password) {
    return Toast.fail(t('两次密码不一致'));
  }

  if (!regParams.pay_password) {
    return Toast.fail(t('请设置支付密码'));
  }

  if (codeType.value == 1 && !regParams.code && showUsernameReg.value) {
    return Toast.fail(t('请输入验证码'));
  }

  if (codeType.value == 3 && geetStatus.value != 4 && showUsernameReg.value) {
    return Toast.fail(t('请点击进行验证'));
  }

  doRegister(regParams).then((res) => {
    if (res.result == 200000) {
      Toast.success(t('注册成功'));
      store.dispatch("saveLoginInfosActions", res.data);
      store.commit('setLoginRegisterInfo', { flag: false }) // 关闭登录注册弹框
      wsConnection.initWebSocket()
      location.reload()
    } else {
      showVerifyCode.value = false
      // if (showUsernameReg.value) {
      //   if (codeType.value == 3) {
      //     handleGetCodeType()
      //   } else {
      //     handleGetGraphicCode()
      //   }
      // }
      Toast.fail(res.msg)
    }
  });
}
// 是否显示用户名或手机号/邮箱注册, ture: 账号注册， false: 手机号/邮箱注册
const showUsernameReg = ref(true)

// 点击切换注册类型
const handleChangeRegType = () => {
  showUsernameReg.value = !showUsernameReg.value
  regParams.username = ""
  regParams.password = ""
  regParams.confirm_password = ""
  regParams.pay_password = ""
  regParams.code = ""
  regParams.session = ""
}

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const closeRegister = () => {
  emit('update:showRegister', false)
}

const setShowServicePopup = (flag) => {
  store.commit('setShowServicePopup', flag)
}

const isCountDownFinish = ref(false)

const countDownFinish = () => {
  isCountDownFinish.value = true
}


// 手机注册时的第一步的下一步操作
const phoneNextStep = () => {
  showVerifyCode.value = true
}

watch(() => showVerifyCode.value, val => {
  if (val) {
    handleGetCodeType()
  }
})

watch(() => props.show, val => {
  if (!val) {
    step.value = 1
  }
})

const nextStep = () => {
  if (!regParams.username) {
    return Toast.fail(t('请输入账号'));
  }
  step.value += 1
}
</script>

<style lang="less" scoped>
.register-wrap {
  position: relative;
  height: 100%;
  padding: 0 16px;

  .logo-box {
    position: relative;
    text-align: center;

    .van-image {
      width: 168px;
      margin-top: 38px;
    }
  }

  .register-out-box {

    .register-box {
      .last-step {
        position: absolute;
        top: 7px;
        left: 5px;
        font-size: 23px;
        z-index: 100;
      }

      .title {
        padding-top: 42px;
        margin-bottom: 10px;
        font-family: PingFang SC;
        font-size: 28px;
        font-weight: 400;
        line-height: 39px;
        color: #333;
      }

      .sub-title {
        margin-bottom: 4px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        color: #7C8083;
      }

      .verify-code-title {
        display: flex;
        align-items: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.5px;
        color: #666;

        .van-image {
          width: 24px;
          height: 24px;
          margin-left: 4px;
          margin-bottom: 4px;
        }
      }

      .count-down-wrapper {
        min-width: 59px;
        height: 28px;
        padding: 0 10px;
        margin-right: 14px;
        border-radius: 6px;
        background: #E6E6E6;

        .time {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.5px;
          text-align: center;
          color: #2EBD85;
        }

      }

      .go-login {
        margin-top: 14px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.5px;
        color: #2EBD85;
      }

      :deep(.van-cell) {
        height: 40px;
        margin-bottom: 12px;
        padding: 0 0 0 23px;
        background: #F5F5F5;
        border-radius: 8px;


        &::after {
          border: none;
        }

        input {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.5px;

          &::placeholder {
            color: #A4A4A4;
            font-size: 12px;
          }
        }

        .eye-icon {
          display: flex;
          align-items: center;
          padding-right: 12px;

          .van-image {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  .service-forget {
    position: fixed;
    left: 50%;
    bottom: 18px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #2EBD85;
    white-space: nowrap;

    .van-image {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }

  .btn {
    width: 100%;
    height: 47px;
    margin-top: 30px;
    border-radius: 8px;
    background: #2EBD85;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
    color: #333;
  }
}
</style>

<style lang="less">
.verify-code-dialog {
  width: 308px;
  padding-bottom: 24px;

  .van-dialog__content {
    padding: 0 7px;
  }

  .close-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 20px;
    height: 20px;
  }

  .label {
    padding-top: 36px;
    margin-bottom: 8px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #666;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .van-cell {
      width: 200px;
      height: 40px;
      padding: 0 0 0 23px;
      background: #F5F5F5;
      border-radius: 8px;

      &::after {
        border: none;
      }

      input {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;

        &::placeholder {
          color: #A4A4A4;
        }
      }
    }

    .code-img {
      width: 90px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .btn {
    margin-top: 26px;
  }
}
</style>
