<template>
  <div class="search-wrap">
    <div class="top-search">
      <div class="search-input">
        <van-search
            id="searchRef"
            v-model="search"
            clearable
            :placeholder="$t('请输入搜索的银行')"
            shape="round"
            @update:model-value="handleUpdate"
        />
      </div>
    </div>

    <div id="searchList" :class="{'loading': loading }" class="search-list">
      <van-cell-group v-if="searchResults.length > 0">
        <van-cell
            class="list-cell"
            v-for="(item, index) in searchResults"
            :key="index" :title="item.name"
            @click="handleCurrent(item)"
        >
          <template #right-icon>
            <van-image :src="themeImgs.commonCsYesIcon" v-if="selectedBank === item.id" class="active-icon" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref, defineEmits, computed} from 'vue'
import {useRouter} from "vue-router"
import {getBankList} from '@/api/api'
import {useStore} from "vuex";

const emit = defineEmits(['setSelectedBank'])
const router = useRouter()
const store = useStore();

// 获取银行列表数据
const bankActions = ref([])
const searchResults = ref([])

const loading = ref(false)

const handleGetBankList = () => {
  loading.value = true
  getBankList().then(res => {
    if (res.result == 200000) {
      Object.getOwnPropertyNames(res.data).forEach(function (key) {
        let obj = {}
        obj.name = res.data[key]
        obj.id = key
        bankActions.value.push(obj)
        searchResults.value.push(obj)
        loading.value = false
      })
    } else {
      loading.value = false
      bankActions.value = []
      searchResults.value = []
    }
  })
}

onMounted(() => {
  handleGetBankList()
  // window.addEventListener('touchmove', closeKeyboardOnScroll)
})

onBeforeUnmount(() => {
  // window.removeEventListener('touchmove', closeKeyboardOnScroll)
})

// const closeKeyboardOnScroll = () => {
//   if (document.hasFocus) {
//     const inputId = document.getElementById('searchRef')
//     inputId.blur()
//     document.activeElement.blur()
//   }
// }

const search = ref('')

//  银行搜索执行
function fuzzySearch(query) {
  if (!query) {
    return bankActions.value
  }
  query = query.toLowerCase()
  const results = bankActions.value.filter(item => item.name.toLowerCase().includes(query))
  return results
}

const selectedBank = ref(null)
//  选择银行
const handleCurrent = (item) => {
  // router.replace({
  //   path: '/addPayment',
  //   query: {
  //     search: JSON.stringify(item),
  //     currentIndex: 3
  //   }
  // })

  selectedBank.value = item.id
  console.log('item', item)
  emit('setSelectedBank', item )
}

const handleUpdate = (value) => {
  if (value === '') {
    searchResults.value = [...bankActions.value]
  } else {
    const data = fuzzySearch(value)
    searchResults.value = data
  }
}

const themeImgs = computed(() => store.state.themeImgs)

</script>

<style lang="less" scoped>
.search-wrap {
  position: relative;
  display: flex;
  height: 100%;
  padding-top: 60px;
  overflow: hidden;

  .top-search {
    position: absolute;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;

    ::v-deep .van-search__content {
      background: var(--orderSearchNumInputBgColor);
    }

    .search-input {
      width: 100%;

      ::v-deep {
        .van-search__content {
          padding-left: 10px;
          border-radius: 10px;
          overflow: hidden;

          .van-search__field {
            padding: 5px 0;
            background: #F4F4F4;
          }
        }
      }

      ::v-deep .van-field__control {
        color: var(--van-field-input-text-color);
      }
    }
  }

  .list-cell {
    display: flex;
    align-items: center;
    //margin-bottom: 10px;
    //border-radius: 8px;
    border-top: 1px solid rgba(151, 151, 151, 0.1);
    background: transparent;

    ::v-deep {
      .active-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .search-list {
    position: relative;
    width: 100%;
    overflow-y: auto;

    &:empty:after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: 'No Data';
      color: #535c66;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.loading {
      &:empty:after {
        content: 'Loading...';
      }
    }
  }
}
</style>
