<template>
  <van-popup v-model:show="show"
             class="login-register-popup"
             position="bottom"
             @closed="closeDialog"
  >
    <van-image class="close-icon" :src="themeImgs.loginRegCloseIcon" @click="closeDialog" />

    <Login v-if="!showRegister" v-model:showRegister="showRegister" :show="show"/>
    <Register v-if="showRegister" v-model:showRegister="showRegister" :show="show" />
  </van-popup>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import Login from './components/Login'
import Register from './components/Register'
import {useStore} from "vuex";

// defineOptions({
//   name: 'LoginAndRegister'
// })

const store = useStore()
const show = ref(false)

const loginRegisterInfo = computed(() => store.state.loginRegisterInfo)

watch(() => loginRegisterInfo.value.flag, val => {
  show.value = val
})

const showRegister = ref(false)

watch(() => loginRegisterInfo.value.type, val => {
  if (val === 2) {
    showRegister.value = true
  }
})

const closeDialog = () => {
  store.commit('setLoginRegisterInfo', { flag: false })
  showRegister.value = false
}
const themeImgs = computed(() => store.state.themeImgs)

</script>

<style lang='less' scoped>
.login-register-popup {
  width: 210px;

  .close-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 20px;
    height: 20px;
    z-index: 100;
  }
}
</style>

<style lang='less'>
.login-register-popup {
  height: 90%;
  border-radius: 12px 12px 0 0;
}
</style>
